.eIpoText{
    letter-spacing: var(--unnamed-character-spacing-0-12);
color: var(--unnamed-color-787878);
text-align: left;
font: normal normal medium 20px/30px Montserrat;
letter-spacing: 0.12px;
color: #787878;
}

.waranImg{
    margin: auto;
    width: 100%;
}

.appendixPdfButton:hover{
    color:white;
    background-color: #0071bf !important;
    border-color:#0071bf !important;
    /* width: 100%; */

}