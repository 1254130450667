.leftSide {
  padding-right: 3%;
}
.path {
  display: flex;
  width: 66%;
  /* font-family: StemRegular; */
  height: 100%;
}
.appPageLocation {
  justify-content: center !important;
}
#blogSpacer {
  height: 85px;
}
.rightSide {
  padding-left: 3%;
}
.blogSubPath {
  /* justify-content: space-between !important; */
  flex-wrap: wrap;
}
.subPathContainer {
  display: flex;
  width: 60%;
  text-align: center;
  justify-content: space-between;
  margin: auto;
}
.subPathWrapper {
  /* height: 100%; */
  top: auto !important;
  background-color: white;
  padding-top: 5rem;
  z-index: 500 !important;
  transition: 0.3s;
  /* box-shadow: 0px 4px 14px rgb(0 0 0 / 7%);  */
}
.rnaSpacer {
  height: 20px;
}
.subPathSpacer {
  transition: none !important;
}
.subPathButton {
  font-size: 14px;
  /* transition: 0.3s; */
  background-color: transparent;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.subPath:hover {
  color: white;
  cursor: pointer;
  background-color: #0067ba;
  /* font-family: TypoldBold; */
  color: white !important;
  border-color: #0067ba;
  text-shadow: 0px 0px 1px white;
}
.subPathActive {
  background-color: #0067ba;
  /* font-family: TypoldBold; */
  color: white !important;
  text-shadow: 0px 0px 1px white;
  cursor: initial !important;
  border-color: #0067ba;
  transition: none;
}
/* .subPathButton:hover {
  color: white;
  cursor: pointer;
  background-color: #0061ad;
  font-family: TypoldBold;
  color: white !important;
  font-weight: 900;
} */
.pageLocationWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  /* margin-top: 0.3rem; */
  /* margin-bottom: 1rem; */
}
.subPathButtonActive {
  background-color: #0061ad;

  color: white !important;
  font-weight: 900;
  cursor: initial !important;
}
.leftSide {
  cursor: pointer;
}
@media only screen and (max-width: 1400px) {
  .subPathContainer {
    width: 85%;
  }
}
@media only screen and (max-width: 1000px) {
  .subPathContainer {
    width: 95%;
  }
  #blogSpacer {
    height: 35px;
  }

}
@media only screen and (max-width: 768px) {
  .blogSubPath {
    justify-content: center !important;
    row-gap: 0rem !important;
  }
  
  .blogSubPath > .subPath {
    width: auto;
    padding-left: 1.2rem;
    padding-right: 1.2rem;
    margin-top: 0.3rem;
  }
  .subPathContainer {
    width: 100%;
    padding: 10px;
    /* display: grid;
    grid-template-columns: 50% 50%; */
  }
  .subPathWrapper {
    margin-top: 0rem;
  }
  .subPath {
    width: 90%;
    font-size: 8px;
    margin-top: 1rem;
  }
  /* #Syariah{
    margin-left: 50% !important;
  } */
  .mx-5 {
    margin-left: 1rem !important;
  }
  .mobileNavbar {
    display: block !important;
  }
  .leftSide {
    padding-right: 5% !important;
  }
  .rightSide {
    padding-left: 5% !important;
  }
  .path {
    font-size: 0.8rem;
    width: 90%;
  }

  .pageLocationWrapper {
    display: block;
  }
  .subPathButton {
    font-size: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .subPathWrapper {
    margin-right: 0px;
  }
  #blogSpacer {
    height: 35px;
  }
}
