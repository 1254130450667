.aboutButton {
  background-color: white;
  border: 1px solid #707070;
  width: 90%;
  padding: 5px 0px 5px 0px;
  transition: 0.2s;
  /* margin: auto; */
  margin-top: 2rem;
  color: #808080;
  position: relative;
  font-size: 15px !important;
}
.abouButtonDiv {
}
.pimpinanWrapper {
  margin: auto;
}
.downloadImgAbout {
  height: 1.3rem;
  position: absolute;
  right: 0;
  margin-right: 15px;
  top: 50%;
  filter: invert(47%) sepia(0%) saturate(0%) hue-rotate(155deg) brightness(92%)
    contrast(86%);
  transform: translateY(-50%);
  transition: 0.2s;
}
.aboutButton:hover .downloadImgAbout {
  filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(257deg)
    brightness(105%) contrast(104%);
}
.profileArticleContainerLeft {
  margin-right: 3rem;

  text-align: justify;
}
.profileArticleContainerRight {
  text-align: justify;
}
.superheroImg {
  width: 100%;
}
.justify {
  text-justify: inter-word !important;
}
.profileArticleContainer {
  display: grid !important;
  grid-template-columns: 40% 60%;
  margin-top: 3rem !important;
  color: black !important;
}
.profileTextWrapper {
  width: 60%;
  margin: auto;
  margin-top: 6rem;
  line-height: 180%;
  margin-bottom: 7rem;
}
.aboutButton:hover {
  background-color: #0060ad;
  color: white;
  border-color: #0060ad;
  text-shadow: 0px 0px 1px white;
}
.aboutButtonContainer {
  margin-top: 0.5rem;
}
.profileArticleContainer {
}
.profileArticleText {
  text-align: justify;
}
.profileTitle {
  line-height: 1.5;
}
.tentangSubmenu {
  padding-left: 3.7rem;
  position: relative;
  transition: 0.2s;
}

.tentangSubmenu:hover {
  background-color: #0060ad;
  color: white;
}
.pimpinanSection {
  margin-top: 8rem;
}
.orangeText {
  color: #fbb03b;
}
.pimpinanTab {
  margin-top: 6rem;
  margin-bottom: 8rem;
}
.pimpinanLeft {
  margin-right: 7%;
}
.pimpinanRight {
  margin-left: 7%;
}
.pimpinanWrapper {
  /* width: 100%; */
}
.pimpinanImgContainer {
  background-image: url(../../assets/pimpinanBg.jpg);
  height: 20rem;
  width: 20rem;
  background-size: contain;
  text-align: center;
}
.pimpinanImg {
  width: 69%;
  text-align: center;
  margin: auto;
}
.timelineContainer {
  width: 100%;
  margin-top: 8rem;
}
.timelineCircle {
  width: 1.3rem;
  height: 1.3rem;
  background-color: #0060ad;
  border-radius: 50%;
  margin: auto;
}

.timelineLeft {
  width: 50%;
  border-right: 3px solid #0060ad;
}
.timelineRight {
  width: 50%;
  border-left: 3px solid #0060ad;
}

.yearLeftWrapper {
  height: 4rem;
}
.timelineLeftDescription {
  text-align: right;
  margin-right: 4.5rem !important;
}
.timelineRightDescription {
  margin-left: 4.5rem !important;
}
.timelineHalfSpacer {
  width: 3rem !important;
}

.mobileHalfSpacer {
  height: 3rem;
  padding-left: 2rem;
  padding-top: 0.7rem;
}
.timelineMobileContainer {
  width: 100%;
  display: grid;
  grid-template-columns: 12% 88%;
  padding-left: 2rem;
}
.timelineMobileContainer {
  display: none;
}
.mobileBranchAndYear {
  display: flex;
  height: 2rem;
}
.mobileTimelineText {
  height: 6rem;
  padding-left: 2rem;
  padding-top: 0.7rem;
}
.mobileBranch {
  height: 50%;
  width: 1rem;
  border-bottom: 3px solid #0060ad;
}
.timelineCircleMobile {
  width: 1rem;
  height: 1rem;
  border: 3px solid #0060ad;
  margin-left: auto;
  margin-right: -0.4rem;
  border-radius: 50%;
  background-color: #0060ad;
}
.timelineMobileLeft {
  width: 100%;
  height: 100%;
  border-right: 4px solid #0060ad;
}
.titleMobile {
  display: none;
}
.visiMisiContainer {
  position: relative;
  background-size: cover;
  background-image: url(../../assets/visiBg.jpg);
}

.visiMisiBgContainer {
  position: relative;
}
.visiContainer {
  margin-bottom: 4rem;
}
.visiMisiRight {
  display: grid;
  grid-template-columns: 30% 70%;
  background-position: center;
}
.misiWrapper {
  display: grid;
  grid-template-columns: 40% 60%;
  line-height: 2;
}
.misiLeft {
  border-right: 2px solid white;
  padding-right: 2rem;
}
.misiRight {
  padding-left: 3rem;
}
.visiMisiWrapper {
  padding-top: 2rem;
  padding-bottom: 2rem;
  display: grid;
  grid-template-columns: 30% 70%;
  padding-right: 7rem;

}
.visiMisiImg {
  width: 80%;
}
.tentangSubmenu:hover .downloadImgAbout {
  filter: invert(100%) sepia(100%) saturate(14%) hue-rotate(257deg)
    brightness(105%) contrast(104%);
}
.aboutButtonWrapper {
  width: 70%;
  text-align: center;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin: auto;
  justify-content: space-between;
}
.aboutButtonWrapper2 {
  width: 70%;
  text-align: center;
  display: grid;
  grid-template-columns: 10% 20% 20% 20% 20% 10%;
  margin: auto;
  justify-content: space-between;
}
.aboutButtonActive {
  background-color: #0060ad;
  color: white;
  border-color: #0060ad !important;
  text-shadow: 0px 0px 1px white;
}
.piagamButton {
  /* height: 40px; */
  background-color: white;
  border: 1px solid #707070 !important;
  width: 12.2%;
  left: 57.7%;
  padding: 5px 0px 5px 0px;
  transition: 0.2s;
  margin: auto;
  margin-top: 2rem;
  color: #808080;
  position: absolute;
  border-radius: 35px;
  font-size: 15px !important;
  vertical-align: middle !important;
}
.aboutMenmargin-topuHover {
  height: 100%;
  width: 100%;
  vertical-align: middle !important;
  display: flex !important;
  align-items: center !important;
}
.piagamButtonHidden {
  max-height: 0;
  height: 0;
  opacity: 0;

  text-align: left;
  pointer-events: none;
}
.piagamText {
  width: 100%;
}

.piagamBottom {
  z-index: 2;
}
.pedomanButton {
  left: 71.2% !important;
  z-index: 1;
}
.piagamButton:hover .piagamText {
  display: none;
}
.piagamButtonHidden:active {
  transition: 0.5s;
}
.piagamButton:hover .piagamButtonHidden {
  height: auto;
  max-height: 500px;
  opacity: 1;
  pointer-events: auto;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  transition: 0.6s;
}
.piagamButton:hover {
  transition: 0.2s;
  border-radius: 14px !important;
  height: auto;
  width: 380px;
  transition: 0s height;
  border-width: 2px !important;
}
.pedomanButton:hover {
  width: 420px !important;
}
.tentangSelectContainer {
  display: none;
}
.piagamButton:hover .piagamMenu {
  display: none;
}
.pedomanAbout:hover .profileTitle {
  height: 100vh;
}
.pedomanAbout {
  display: block;
}
.pedomanAboutHover {
  display: none;
}
.videoPlayerWrapper {
  height: 100%;

}
.videoPlayer {
  text-align: center;
}

.oval {
  height: 100px;
  width: 60%;
  border-radius: 75px;
  border: 5px solid #0061ad;
}
.tentangKamiFlexEnd {
  justify-content: flex-end;
}
.tentangKamiFlexStart {
  justify-content: flex-start;
}
.tentangKamiCard {
  grid-template-columns: 35% 65%;
  cursor: pointer;
}
.tentangKamiContainer {
  margin: auto;
}
.tentangKamiImg {
  padding: auto;
  max-width: 100%;
  max-height: 100%;
}

.tentangKamiCaption {
  vertical-align: middle;
  height: auto;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 15%;
}

.imageWrapper {
  border-right: 2px solid #99bfde;
  height: 100%;
}
.tentangKamiLeftWrapper {
  width: 48vw;
  margin-right: 1vw;
}

.tentangKamiRightWrapper {
  width: 48vw;
  margin-left: 1vw;
}
.optionDownload {
  background-size: cover;
}
@media only screen and (max-width: 1500px) {
  .aboutButtonWrapper {
    width: 80% !important;
  }
  .aboutButton {
    width: 195px;
  }
  .aboutButtonWrapper2 {
    width: 80% !important;
  }
  .pedomanButton {
    left: 74.3% !important;
    width: 195px;
  }
  .piagamBottom {
    left: 58.75% !important;
    width: 195px;
  }
}

@media only screen and (max-width: 1400px) {
  .tentangKamiCaption {
    margin-left: 8%;
  }

  .oval {
    width: 73%;
  }
  .aboutButtonContainer {
    margin-top: 3.2rem;
  }
}
@media only screen and (max-width: 1300px) {
  .aboutButtonWrapper {
    width: 95% !important;
  }
  .aboutButtonWrapper2 {
    width: 95% !important;
  }
  .piagamBottom {
    left: 60.3% !important;
  }
  .pedomanButton {
    left: 78.7% !important;
  }
}
@media only screen and (max-width: 1200px) {
  .aboutButtonWrapper {
    width: 90%;
  }
}

@media only screen and (max-width: 1000px) {
  .aboutButton {
    font-size: 0.7rem;
  }
  .profileTextWrapper {
    margin-top: 8rem;
  }
  .aboutButtonContainer {
    display: none;
  }
  .tentangSelectContainer {
    display: block;
  }
  .subPathSelectAbout {
    width: 24rem !important;
    margin: auto;

    padding-left: 2rem;
    position: absolute;
    margin-bottom: 10rem;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    z-index: 50;
    margin-top: 0rem !important;
  }
  .profileArticleText {
    text-align: center;
  }
  .ceritaImgContainer {
    display: none !important;
  }
  .profileArticleContainer {
    display: block !important;
    width: 90%;
    margin: auto;
    margin-top: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .timelineContainer {
    display: none;
  }
  .profileArticleContainer {
    display: block !important;
    width: 95%;
  }
  .profileTextWrapper {
    margin-bottom: 4rem;
  }
  .titleMobile {
    display: block;
  }
  .pimpinantab .profileButtonContainer {
    margin-top: 4rem;
  }
  .w100.mx-auto.pimpinanTabWrapper {
    margin-top: -5rem !important;
  }
  .flex.my-5.py-5.pimpinanTabWrapper {
    margin-top: -2rem !important;
  }
  .pimpinanLeft {
    margin-right: 0rem !important;
  }
  .pimpinanRight {
    margin-left: 0rem !important;
  }
  .pimpinanTabWrapper {
    display: block !important;
  }
  .timelineMobileContainer {
    display: grid;
  }
  .alamatWrapper {
    width: 85% !important;
  }
  .subPathSelectAbout {
    width: 16rem !important;
  }
  .aboutButtonContainer {
    margin-top: -3rem;
  }
  .aboutButtonWrapper {
    grid-template-columns: 47% 47%;
  }
  .aboutButton {
    margin-top: 1rem;
  }
  .tentangKamiLeftWrapper {
    width: 100%;
  }
  .tentangKamiRightWrapper {
    width: 100%;
  }
  .tentangKamiContainer {
    display: block;
  }
  .oval {
    width: 80%;
  }
  .tentangKamiFlexEnd {
    justify-content: center;
  }
  .tentangKamiFlexStart {
    justify-content: center;
  }
  .aboutButtonWrapper2 {
    width: 100% !important;
    display: block;
  }
  .aboutButton {

  }
  .aboutButtonWrapper {
    width: 100% !important;
    display: block;
    margin-top: 0;
  }
  .aboutButtonContainer {
   
  }

  .timelineHalfSpacer {
    padding-left: 0.2rem !important;
    padding-right: 0.2rem !important;
    width: 1rem !important;
  }
  .timelineLeftDescription {
    margin-right: 0.4rem !important;
    font-size: 0.9rem !important;
  }
  .timelineRightDescription {
    margin-left: 0.4rem !important;
    font-size: 0.9rem !important;
  }
  .timelineWrapper {

  }
  .yearLeftWrapper {
    height: 5rem;
  }

  .superheroImg {
    display: none !important;
  }
  .visiMisiImg {
    width: 100%;
  }
  .visiMisiWrapper {

    width: 100%;
    padding-right: 0rem;
    grid-template-columns: 40% 60%;
  }
  .visiMisiContent {

    padding-right: 1rem;
    font-size: 1rem !important;
  }
  .misiWrapper {
    display: block;
    grid-template-columns: 50% 50%;

  }
  .misiLeft {
    border: none;
    padding-right: 0rem !important;
  }
  .misiRight {
    padding-left: 0rem !important;
  }
  .visiContainer {
    margin-bottom: 2rem;
  }
  .profileButton {
    width: 10rem !important;
    font-size: 0.8rem;
  }
  .pimpinanImgContainer {
    width: 15rem;
    height: 15rem;
  }
  .titleMobile.profileTitle.f25r {
    margin-top: 3rem;
    margin-bottom: 4rem;
  }
}
