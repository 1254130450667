.followSocmedContainer{
    
    
    text-align: center;
width: 100%;
   padding-top: 2rem;
    margin-bottom: 5rem;
}
.followTitle{
    font-size: 1.4rem;
}
.followSocmedImgContainer{

  
text-align: center;
    margin:auto;
    margin-top:1rem
}
.followSocmedWrapper{
    width: fit-content;
    margin: auto;
    border-radius: 25px;
    padding-left: 6rem;
    padding-right: 6rem;
}
.followSocmedButton{
    background-color: #0071bf;
    border-radius: 12px;
    border: none;
    color: white;
    padding-left: 4rem;
    padding-right: 4rem;
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.socmed {
   width: 2rem;
    cursor: pointer;
    transition: 0.3s;
    /* filter: brightness(0%); */
    margin-left:0.4rem;
    margin-right:0.4rem
  }

  @media only screen and (max-width: 768px) {
	.followSocmedContainer{
		padding-top: 1.5rem;
        width: 85%;
        margin: auto;
        margin-bottom: 2rem;
	}
    .followText{
        width: 80%;
        margin: auto;
    }
    .followTitle{
        font-size: 1.3rem !important;
    }
    .followSocmedImgContainer{
        margin-top: 2rem;
    }
    .followSocmedWrapper{
        padding-left: 2rem;
        padding-right: 2rem;
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;

    }
    .followSocmedImgContainer{

  
            margin-top:0.6rem
        }
        .socmed{
            width: 1.4rem;
        }
}
