.newsDetailTitle{
    margin: auto;
    font-size: 2.4rem;
    /* width: 50%; */
    max-width: 80%;;
    text-align: center;
    /* margin-top: 3rem; */
}

.mainArticleContainer2 {
  height: 36rem;
 /* border-radius: 10px; */
  background-repeat: "no-repeat" !important;
  width: 60%;
  background-position: center;
  margin: auto;
  vertical-align: bottom;
  margin-top:2rem;
  transition:0.2s
}
.articleContentContainer{
  margin: auto;
  display: grid;
  /* grid-template-columns: 50% 50%; */
  width: 60%;
}
.articleContentWrapper{
  /* margin-top: 2rem; */
}
.socmedWrapper2 {
    display: flex;
    width: 20%;
    margin: auto;
    margin-top:1.3rem
  }
  .socmed2 {
    max-width: 45%;
    cursor: pointer;
  }
  .newsDetailSource{
    margin-left: 15%;
    margin-top:1rem
  }
  .newsContentContainer{

    /* margin-left: 7.5%; */


    height: auto;
  }
  .artikelSpacer{
    border: 1px solid 
   
#e8e8e7;
width: 60%;
margin: auto;
  }
  .artikelSocmed{
    width: 2rem !important;
  }
.formattedArticleContent{
  white-space: pre-wrap;
  margin-bottom: 7rem;
}
  .floatingList2{
    height: auto;
    background-color: black;
    width: 30%;
    position: -webkit-sticky; /* Safari & IE */
    position: sticky;
    top: 20vh;
  
    margin-top: 5rem;
   
    margin-bottom:5rem;
    margin-left:61%
  
  
  
  }
  .newsByIdDiv{
    /* margin-top:1rem */
  }
  .promoThumbnail img {
    width: 100%;
    height: auto; /* This will maintain the aspect ratio */
  }
  @media only screen and (max-width:1400px) {
    .mainArticleContainer2{
      width: 80%;
    }
    .newsDetailTitle{
      width: 80%;
    }
  }
 
  @media only screen and (max-width: 1150px) {
    .articleContentContainer{
      display: block;
    }
    .mainArticleContainer2{
      height: 20rem;
    }
  }

  @media only screen and (max-width:768px) {
    .articleContentContainer{
      margin-top: 1rem;
      display: block;
      width: 95%;
    }
    .newsContentContainer{
      margin-left: 0px;
      margin:auto;
      width: 100%;
      
    }
    .mainArticleContainer2{
      height: 13rem;
      width: 95%;
    }
    .newsDetailTitle{
      font-size: 1.3rem !important;
      width: 90%;
    }
    .socmed2{
      max-width: 60%;
    }
    .socmedWrapper2{
      width: 60%;
    }
    .newsByIdDiv{
      margin-top:3rem
    }
    .newsByIdDiv .socmedFooter{
      width:fit-content
    }
    .formattedArticleContent{
      margin-bottom: 3rem;
    }
    .newsByIdDiv .my-5.pb-4{
      margin-top:1rem !important;
      margin-bottom:1rem !important;
      padding-bottom:0.5rem !important
    }
  }