.eIpoText {
  letter-spacing: var(--unnamed-character-spacing-0-12);
  color: var(--unnamed-color-787878);
  text-align: left;
  font: normal normal medium 20px/30px Montserrat;
  letter-spacing: 0.12px;
  color: #787878;
}

.waranImg {
  margin: auto;
  width: 100%;
}
.swalPanduan{
  width: fit-content;
  border-radius: 16px !important;
  padding-bottom: 3.5rem;
}
.appendixPdfButton:hover {
  color: white;
  background-color: #0071bf !important;
  border-color: #0071bf !important;
}
.panduanMain {
  gap: 3.5%;
  
}
.panduanWrapper {
  max-width: 72%;
}
.panduanPopup{
  width: 300px !important;
/* ;flex-basis: 1;flex-shrink: 1; */
}
.panduanDetailContainer {

  border-radius: 16px;
  width: 31%;
  background-color: #6c99d7;
  display:flex;flex-direction: column;justify-content:space-between
}
.panduanSubPath {
  width: fit-content;
  position: relative;
  z-index: 99;
  border-width: 1.6px !important;
}
.py18r.table-center.h100 {
  height: 7.99rem;
}
.panduanImg {
  margin-top: -4rem;
  position: relative;
  z-index: 2;
}
.manualButton {
  background-color: #dfebf2;
  transition: 0.15s;
  border: 2px solid #0071bf;
}
.manualButton:hover {
  background-color: #6c99d7;
  color: white;
  border-color: white;
}
.panduanButton {
  background-color: transparent !important;
  padding: 0rem 1rem 0rem 1rem !important;
  border: 1px solid #707070;
  border-radius: 12px;
}
.panduanButton:hover {
  background-color: #0071bf !important ;
  border-color: #0071bf !important;
  color: white;
  background-color: #0071bf;
  color: white !important;
  text-shadow: none !important;
  box-shadow: 0 1px 10px rgb(0 113 191);
}


@media only screen and (max-width: 1200px) {


  .panduanDetailContainer {

    width: 300px !important;
    margin-left: auto !important;
    margin-right: auto !important;
    margin-top: 1.2rem;

  }
}

@media only screen and (max-width: 1000px) {
  .panduanWrapper {
    max-width: 98% !important;
    
  }

  .iframeKoinsContainer.center-vertical.iframeHome {
    width: auto !important;
  }
}
