html {
  scroll-behavior: smooth !important;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
}
iframe {
  height: 560px;
  width: 60%;
}
.iframe-koins {
  width: 100% !important;
}
.downloadImgPanduan {
  cursor: pointer;
}
.h0 {
  height: 0px !important;
}
.text-decor-none{
  text-decoration: none;
}
.hoverBlue:hover {
  background-color: #0071bf !important;
  border-color: #0071bf !important;
  color: white !important;
  text-shadow: none !important;
  box-shadow: 0 1px 10px rgb(0 113 191);
  transition: 0.2s;
  font-weight: 600 !important;
}
.mheight80 {
  min-height: 80vh;
}
.pageContainer {
  margin-top: 7rem;
  margin-bottom: 6rem;
}
.h35r {
  height: 35rem;
}
.mheight90 {
  min-height: 100vh;
}
.bannerSubtitle.kisiMobileSmallText {
  line-height: 1.5;
}
.text-none {
  text-decoration: none !important;
}
.c-tr {
  color: transparent;
}

.mh20r {
  min-height: 35rem;
}
.h3reset {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  margin: 0 0 20px 0;
  line-height: 1.5;
}
.text-danger {
  color: #a94442 !important;
}
.swal2-confirm {
  display: none !important;
}
.none-desktop {
  display: none;
}
body {
  /* overflow: visible !important; */
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin-top: 111px;
}
html,
body {
  overflow-x: hidden;
}
.w65{
  width: 65%;
}
body {
  position: relative;
  z-index: 1 !important;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.tentangVideo {
  width: 100%;
  height: 750px;
}
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;400;500;600;700;800;900&display=swap");

/* @font-face {
  font-family: "StemRegular";
  src: local("StemRegular"),
    url(../src/assets/StemRegular.otf) format("opentype");
  
}
@font-face {
  font-family: "TypoldThin";
  src: local("TypoldThin"),
    url(../src/assets/TypoldThin.otf) format("opentype");
  
}
@font-face {
  font-family: "GraphieThin";
  src: local("GraphieThin"),
    url(../src/assets/GraphieThin.otf) format("opentype");
  
}
@font-face {
  font-family: "GraphieRegular";
  src: local("GraphieRegular"),
    url(../src/assets/GraphieRegular.otf) format("opentype");
  
}

@font-face {
  font-family: "GigaSansBlack";
  src: local("GigaSansBlack"),
    url(../src/assets/GigaSansBlack.otf) format("opentype");
  
}
@font-face {
  font-family: "TypoldRegular";
  src: local("TypoldRegular"),
    url(../src/assets/TypoldRegular.otf) format("opentype");
  
}
@font-face {
  font-family: "TypoldBold";
  src: local("TypoldBold"),
    url(../src/assets/TypoldBold.otf) format("opentype");
  
}
@font-face {
  font-family: "TypoldExtraBold";
  src: local("TypoldExtraBold"),
    url(../src/assets/TypoldExtraBold.otf) format("opentype");
  
} */

.appSection1 {
  width: 90%;
}
.brMobile {
  display: none;
}
.d-none {
  display: none;
}
.giga {
  font-family: GigaSansBlack;
}

.large {
  font-size: 1em;
}
.blue {
  color: #003c64;
}
.typold {
  font-family: TypoldRegular;
}
.brWeb {
  display: block;
}
.grey {
  color: #707070;
}
.blue-text-1 {
  color: #0071bf;
}
.typoldExtraBold {
  font-family: TypoldExtraBold;
}
.typoldThin {
  font-family: TypoldThin;
}
.rounded-corner {
  border-radius: 45px;
}
.rounded-button {
  border-radius: 30px;
  padding-top: 1.5%;
  padding-bottom: 0.75%;
  /* padding-left:35px;
  padding-right:35px */
}
.text-right {
  text-align: right;
}

.f05r {
  font-size: 0.5rem;
}
.f065r {
  font-size: 0.65rem;
}
.f075r {
  font-size: 0.75rem;
}
.f07r {
  font-size: 0.7rem;
}
.f085r {
  font-size: 0.85rem;
}
.f09r {
  font-size: 0.9rem;
}
.f1r {
  font-size: 1rem;
}
.f11r {
  font-size: 1.1rem;
}
.f15r {
  font-size: 1.5rem;
}
.lh14 {
  line-height: 1.4;
}
.lh2 {
  line-height: 2;
}
.lh25 {
  line-height: 2.5;
}
.f165r {
  font-size: 1.65rem;
}
.f175r {
  font-size: 1.75rem;
}
.f185r {
  font-size: 1.85rem;
}
.f12r {
  font-size: 1.2rem;
}
.f13r {
  font-size: 1.3rem;
}
.f14r {
  font-size: 1.4rem;
}

.f2r {
  font-size: 2rem;
}
.f21r {
  font-size: 2.1rem;
}
.f25r {
  font-size: 2.5rem;
}
.f275r {
  font-size: 2.75rem;
}

.f3r {
  font-size: 3rem;
}
.f35r {
  font-size: 3.5rem;
}
.f4r {
  font-size: 4rem;
}
.f5r {
  font-size: 5rem;
}
.f9r {
  font-size: 9rem;
}
.w120 {
  width: 120%;
}
.fw-700 {
  font-weight: 900 !important;
}
.fw700 {
  font-weight: 700;
}
.fw-800 {
  font-weight: 800 !important;
}
.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.none
.fw-500 {
  font-weight: 500 !important;
}
.center-vertical {
  vertical-align: middle !important;
  display: flex;
  align-items: center !important;
}

iframe {
  border-radius: 8px;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.f35r {
  font-size: 3.5rem;
}
.bottom-vertical {
  vertical-align: bottom !important;
  display: flex !important;
  align-items: flex-end !important;
}
.capping-item{
  width: 13rem;
  justify-content: space-around;
}
figure {
  padding-top: 0rem !important;
  padding-bottom: 1rem;
}
iframe {
  filter: drop-shadow(0px 1px 2px #000);
}
.appSpacerLeft {
}
/* .testiSpacer{
  margin-top: 24px !important;
} */
.sliderArrow {
  cursor: pointer;
}
.font20 {
  font-size: 1em;
}
.bg-biru-muda {
  background-color: #eef9ff;
}
.marginLeft5 {
  margin-left: 5%;
}
.mw70 {
  max-width: 70%;
}
.mw75 {
  max-width: 75%;
}
.mw60 {
  max-width: 60%;
}
.mw90 {
  max-width: 90%;
}
.mw80 {
  max-width: 80%;
}
.px-6 {
  padding-left: 4rem;
  padding-right: 4rem;
}
.mw85 {
  max-width: 85%;
}

.text-left {
  text-align: left;
}
.bottomSpacer {
  height: 13vh;
  background-color: #fdfdfd;
}
.bottom-vertical-noflex {
  vertical-align: bottom !important;
  /* display: flex !important; */
  align-items: flex-end !important;
}
.grid {
  display: grid;
}

.center {
  justify-content: center;
  align-items: center;
}

.typoldBold {
  font-family: TypoldBold;
}

.typoldExtraBold {
  font-family: TypoldExtraBold;
}
.greyText {
  color: #5b5c5e;
}
.o0 {
  opacity: 0;
  transition: opacity 0.13s;
}
.o1 {
  opacity: 1;
  transition: opacity 0.13s;
}
.o1a2s {
  opacity: 1;
  transition: opacity 0.5s;
}

.flex {
  display: flex;
}
.flexEnd {
  justify-content: flex-end;
}
.flexStart {
  justify-content: flex-start;
}
.white {
  color: white;
}
.textCenter {
  text-align: center;
}
.thin-btn {
  padding-top: -1rem !important;
}
.monsterratRegular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
}
.monsterratBold {
  font-family: "Montserrat", sans-serif !important;
  font-weight: 700 !important;
}
.monsterratBlack {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
}
.w80 {
  width: 80%;
}
.monsterratMedium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}
.monsterratSemiBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}
.monsterratExtraBold {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
}
#sisipnews{
width: 100%;
/* height: 100px; */
/* background-color: red; */
}
.monsterratLight {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
}
.nowrap {
  white-space: nowrap;
}
.w90 {
  width: 90%;
}
.w80 {
  width: 80%;
}
.w85 {
  width: 85%;
}
.pengumumanTabelWaranList {
}
.cursor-pointer{
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}
.lightBlueHover:hover{
  color:#009dc8
}
.preview2line{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.tabelPengumumanItem {
  border-radius: 5px;
  margin-bottom: 2px;
  padding-left: 2rem;
  padding-right: 2rem;
  white-space: nowrap;
  overflow: hidden;
}
.tabelPengumumanItem:hover {
  background-color: #0067ba;
  color: white;
}
.pengumumanTabelWaran {
  flex-grow: 1;
}
.panduanTable {
  border-color: #0071bf;
}
.py2r {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}
.py18r {
  padding-top: 1.8rem !important;
  padding-bottom: 1.8rem !important;
}
.h100 {
  height: 100%;
}
.lh13 {
  line-height: 1.3;
}
.table-lightblue-bg {
  background-color: #eef9ff !important;
}
.table-center {
  vertical-align: middle;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
.lh2 {
  line-height: 2;
}

.ytOtherContainer {
  width: 29%;
}


@media only screen and (max-width: 1500px) {
  .panduanWrapper {
    width: 92%;
    max-width: 100%;
  }
}


@media only screen and (max-width: 1500px) {
  .video-container {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    height: 0;
  }
  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .disclaimerPageContainer {
    width: 75%;
  }
}
@media only screen and (max-width: 1400px) {
  .mw901400 {
    max-width: 90% !important;
  }
  iframe {
    height: 450px;
    width: 75%;
  }
  .f12r {
    font-size: 1rem;
  }
  .f275r {
    font-size: 2.25rem;
  }
  .panduanWrapper {
    width: 92%;
    max-width: 100%;
  }
  .panduanMain{
    justify-content:center ;
  }
}
@media only screen and (max-width: 1300px) {
  .w851300{
    width: 85%;
  }
  body {
    margin-top: 87px;
  }
  .disclaimerPageContainer {
    width: 85%;
  }
  .f2r {
    font-size: 1.6rem;
  }
  .f1r {
    font-size: 0.9rem;
  }
}
@media only screen and (max-width: 1200px) {
  .f9r {
    font-size: 6.5rem;
  }
  iframe {
    height: 400px;
    width: 75%;
  }
  .w951200{
    width: 95% !important;
  }
  .none1200 {
    display: none;
  }
}
@media only screen and (max-width: 1050px) {
  figure {
    padding-top: 1rem !important;
    padding-bottom: 0rem;
  }
}

@media only screen and (max-width: 1000px) {
  .w951000{
    width: 95% !important;
  }
  .w901000{
    width: 90% !important;
  }
  .capping-item{
    width: 8rem;
  }
  .mw951000 {
    max-width: 95% !important;
  }
  .putvscall {
    margin-bottom: 2rem !important;
  }
  .block1000 {
    display: block !important;
  }
  .mx-auto1000 {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .mw901000 {
    max-width: 90% !important;
  }
  .mw1001000 {
    max-width: 100% !important;
  }
  .w101000 {
    width: 10% !important;
  }
  .w51000 {
    width: 5% !important;
  }
  .px-5 {
    padding-left: 1.5rem !important;
    padding-right: 1.5rem !important;
  }
  body {
    margin-top: 59px;
  }
  .f9r {
    font-size: 5rem;
  }
  .disclaimerPageContainer {
    width: 95%;
  }
  .video-container-tentang {
    margin-bottom: 3rem;
  }
  iframe {
    height: 400px;
    width: 95%;
  }
  /* iframe {
    width: 90%;
    height:24rem;
  } */
  .rounded-corner {
    border-radius: 1.25rem;
  }
  .f15r {
    font-size: 1.2rem;
  }

  .block1000 {
    display: block !important;
    /* min-width: 100%; */
  }
  .none-1000 {
    display: none;
  }
  .d1000 {
    display: auto !important;
  }
  .wrap1000{
    white-space: wrap !important;
  }
}

@media only screen and (max-width: 768px) {
  .bondIstilahDiv{
    gap:30px
  }
  .bondIstilah{
    font-size: 40px !important;
    flex-basis:45%
  }
  .pl0768{
    padding-left: 0 !important;
  }
  .text-left-768{
    text-align: left !important;
  }

  .my-5{
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }
  .capping-cont{
    justify-content: center !important;
  }
  .subPath{
    border-radius: 6px !important;
  }
  .block768{
display: block !important;
  }
  .w75768 {
    width: 75%;
  }
  .text-center-768{
    text-align: center !important;
  }
  .mw100768 {
    max-width: 100% !important;
  }
  .w100768 {
    width: 100% !important;
  }
  .w90768 {
    max-width: 100% !important;
  }
  .mw95768 {
    max-width: 95% !important;
  }
  .pl10768 {
    padding-left: 10px !important;
  }
  .py-5 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }
  .f12768 {
    font-size: 12px !important;
  }
  .pr1768 {
    padding-right: 1rem !important;
  }
  .downloadImgPanduan {
    height:2rem !important;
  }
  .pl05768 {
    padding-left: 0.5rem !important;
  }
  .panduanOffline1 {
    width: 50% !important;
  }
  .panduanOffline2 {
    width: 25% !important;
  }
  .panduanImg {
    width: 100%;
    margin-top: 1rem;
  }
  .panduanDetailContainer.mb-5{
    margin-bottom: 1rem !important;
  }
  .mb-5{
    margin-bottom: 2rem !important;
  }
  .panduanButton {
    font-size: 0.6rem;
    padding: 0rem 0.6rem 0rem 0.6rem !important;
  }
  .none-desktop {
    display: block;
  }
  .wauto768 {
    width: auto !important;
  }
  .iframeKoinsContainer.center-vertical.iframeHome {
    display: block;
    width: 90vw;
    margin: auto;
  }
  .panduanWrapper {
    width: 96%;
  }
  .f12r {
    font-size: 0.82rem;
  }
  .f1r {
    font-size: 0.82rem;
  }
  .tabelWaran {
    display: none;
  }
  .f09r {
    font-size: 0.7rem;
  }
  .f2r {
    font-size: 1.6rem;
  }
  .f11r {
    font-size: 0.75rem;
  }
  .f14r{
    font-size: 1.2rem;
  }
  .f15r {
    font-size: 1.1rem;
  }
  .f13r {
    font-size: 0.9rem;
  }
  /* iframe {
    width: 90%;
    height:20rem;
  } */
  .f25r {
    font-size: 1.75rem !important;
  }
  .f165r{
    font-size: 1.3rem;
  }
  .f9r {
    font-size: 5rem;
  }
  .f3r {
    font-size: 2rem;
  }
  .f35r {
    font-size: 2rem;
  }
  body {
    margin-top: 59px;
  }
  .f275r {
    font-size: 1.6rem;
  }
  .bg-biru-muda {
    /* width: 100% !important;
    border-radius: 0px !important; */
  }
  .brMobile {
    display: block;
  }
  .rounded-corner {
    border-radius: 41px;
  }
  h2 {
    font-size: 1.45rem !important;
  }
  .mw70768 {
    max-width: 70%;
  }
  .mw90768 {
    max-width: 90%;
  }
  .mw80768 {
    max-width: 80%;
  }
  .brWeb {
    display: none;
  }
  .wAuto768 {
    width: auto !important;
  }
  .f21r {
    font-size: 1.1rem;
  }
  .disclaimerContainer.rounded-corner.bg-biru-muda.px-5.py-5 {
    padding-left: 1.4rem !important;
    padding-right: 1.4rem !important;
    margin-top: 1rem !important;
    padding-top: 1rem !important;
  }
  .disclaimerContainer.rounded-corner.bg-biru-muda.px-5.py-5 li {
    padding-top: 1rem;
  }
  .subPathWrapper.pb-5 {
    padding-bottom: 0rem !important;
  }
  .center768 {
    text-align: center;
  }
  .ilustrasiText {
    font-size: 1rem;
  }
  .poinText {
    font-size: 1.4rem;
  }
  .w80768 {
    max-width: 80% !important;
  }
  .mx-auto-768 {
    margin: auto;
  }
  .none-mobile {
    display: none !important;
  }
  ol {
    padding-left: 1.25rem !important;
    text-align: justify;
    padding-right: 1rem !important;
  }
  .blockMobile {
    display: block !important;
  }
  .pt-5 {
    padding-top: 1.5rem !important;
  }
}
@media only screen and (max-width: 500px) {
  iframe {
    width: 100%;
    height: 15rem;
  }
  .nowrap {
 
  }
}
