.laporanTahunanWrapper {
  /* display: flex; */
  margin-top: 4rem;
}

.laporanTahunanSelector {
  /* width: 20%; */
  text-align: center;
}
.laporanTahunanContainer {
  margin-top: 4rem;

  margin-bottom: 10rem;
  /* width: 80vw; */
  text-align: center;
}
.laporanTahunanYear {
  background-color: #0060ad;
  border-radius: 3rem;
  width: 120%;
}
.laporanTahunanReader {
  height: 160vh;
  width: 85%;
  margin: auto;
}
.dropdown-toggle {
  margin: auto;
}
.laporanDropdown {
  margin: auto;
  margin-top: 2rem;
  margin-left: 3rem;
}
.dropdownDiv {
  margin: auto;
  width: 10%;
}
@media only screen and (max-width: 1400px) {
  .laporanDropdown {
    margin-left: 0rem;
  }
}
@media only screen and (max-width: 768px) {
  .laporanTahunanWrapper {
    display: block;
  }
  .laporanTahunanSelector {
    margin: auto;
    width: 100%;
  }
  .laporanTahunanContainer {
    width: auto;
  }
  .laporanTahunanReader{
    height: 80vh;
  }
}
