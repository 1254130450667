.nav-button {
  border: none;
  padding-left: 1rem !important;
  padding-right: 1rem !important;
  background-color: transparent;
  color: #6e6f72 !important;
  font-size: 0.9rem;
  transition: 0s;
  height: 39px;
  display: flex;
}
.mobileSubmenuWrapper{
  max-height: 0px;
  transition: max-height 0.2s,opacity 0.5s height 0.5s, visibility 0.3s;
  visibility: hidden;
  opacity: 0;
}

.mobileSubmenu{
  padding-top: 30px;
  font-size:14px
}
.mobileSubmenuWrapperActive{
  max-height:50vh !important;
  visibility:visible ;
  opacity: 1;
  transition: 0.2s;

}
.navbarDownload {
  border: 3px solid #0071bf !important;
  font-weight: 700;
  color: #0071bf !important;
  border: none;
  padding-left: 1.2rem !important;
  padding-right: 1.2rem !important;
  background-color: transparent;
  padding-top: 0.2rem !important  ;
  padding-bottom: 0.1rem !important;
  font-size: 0.9rem;
  transition: 0.2s;
  margin-top: -2.4rem;

  display: flex;
}
.navDialogMenu {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}
.navDialogWrapper {
  margin: auto;
  /* top: 12rem; */
}
.navDialogMenu .navDialogWrapper {
  visibility: hidden;

  margin: auto;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  text-align: center;

  transition: .4s ;
  opacity: 0;
margin-top:-0.1rem;
  transform: translate(0,-8px);

}
.navDialogSubmenu {
  height: 43px !important;
  padding: 0.7rem 1.2rem 0.7rem 1.2rem !important;

  font-size: 0.9rem;
  cursor: pointer;
}
.blogPosition{
    /* right: -154px; */
    right: -205px;
}
.productPosition{
  right: -145px;
}
.productSubmenu{
  /* width: 33%; */
}
.navSpacer {
  width: 100%;
  height: 0.7rem;
}
.navDialogSubmenu:hover {
}
.dialogText{
  white-space: nowrap;
}
.dialogText:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  text-shadow: 0px 0px 1px black;
  
  color: #0071bf !important;
  border-bottom: 2px solid #0071bf;
}
.navDialogWrapper:hover .navDialogMenu {
  border-top: none;
  border-left: none;
  border-right: none;
  text-shadow: 0px 0px 1px black;
  color: #0071bf !important;
  border-bottom: 2px solid #0071bf;
}
.dialogText:hover.nav-button {
}


/* .hoverableNav:hover ~ .navDialogWrapper{
  visibility: visible;
  opacity: 1;
  margin-top: 0;

}  */

.navDialogWrapper:hover{
  /* visibility: visible;
  opacity: 1;
  margin-top: 0; */
}
.navText:hover  .navDialogWrapper {
  /* visibility: visible;
  opacity: 1;
  margin-top: 0; */
}
.navDialogMenu:hover .navDialogWrapper {
  visibility: visible;
  opacity: 1;
  margin-top: 0;

}


.navDialogMenu:hover   .navDialogMenu{
  pointer-events: none;
}
.navDialogMenu:hover .navDialog{
  z-index: 1 !important;
}
.navDialogMenu:hover .nav-link{
    color: #0071bf !important;
    border-bottom: 2px solid #0071bf;
    text-shadow: 0px 0px 1px black;
}
.jago-syariah.triangle {
  margin: auto;
  /* left: 30%; */;
  /* margin-top: 0.8rem; */
  padding-top: 0.8rem;


  
}
.triangle {
  content: "";
  position:relative;
  width: 0;
  height: 0;
  left: 0;
  right: 0;
  margin: auto;
  /* top: -20px; */
  bottom: auto;
  border: 10px solid transparent;
  border-bottom-color: white;
  z-index: 99;
  box-shadow: 0 8px 24px rgb(0 0 0 / 15%)inset;
  
}
.navDialog {
  display: flex;
  margin: auto;
  background-color: white;
  box-shadow: 0 8px 24px rgb(0 0 0 / 15%);
  background: #ffffff 0% 0% no-repeat padding-box;
  /* border: 1px solid #787878 ; */

  border-radius: 17px;
  opacity: 1;
  z-index: 2 !important;
  /* top:80px; */
  position: relative;
  /* width: 10rem; */
  /* box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.82);
-webkit-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.82);
-moz-box-shadow: 0px 0px 9px 1px rgba(0,0,0,0.82); */
}
.arrow-up {
  width: 0px;
  height: 0px;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid 
  #0060ad;
  margin: auto;

  
  z-index: 5;
}
.mobileNavbar{
  /* background-color: red;
  height: 100vh; */
}
.collapsing{
  transition: height 0.45s !important
}
.navbarDownload:hover {
  background-color: #0071bf;
  color: white !important;
  text-shadow: none !important;
  box-shadow: 0 1px 10px rgb(0 113 191);
}
.nav-button:hover {
  border-top: none;
  border-left: none;
  border-right: none;
  text-shadow: 0px 0px 1px black;
  color: #0071bf !important;
  border-bottom: 2px solid #0071bf;
  /* font-weight: 900 !important; */
}
.menuActive{
  text-shadow: 0px 0px 1px black;
  color: #0071bf !important;
  border-bottom: 2px solid #0071bf;
  
  /* pointer-events: none; */
  /* cursor: pointer !important; */
}
.mobileNavbar {
  display: none;
}
.mobileNavbarButton {
  display: none;
}

.lowerNavbar {
  display: grid;
  grid-template-columns: 50% 50%;
}
.uppernavbar-divider {
  margin: auto;
  width: 44%;
  display: grid;
  grid-template-columns: 18% 74% 8%;
  padding-top: 10px;
}

.navigationBar {
  /* box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48);
  -webkit-box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48);
  -moz-box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48); */
  height: auto;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 7%);
  /* z-index: 0; */
  /* position: relative; */
  /* padding-top: 1.5rem;
  padding-bottom: 1rem; */
  background-color: white;
}



.searchWrapper {
  position: relative;
}

/* .searchIcon{
    position: absolute;
    top: 12%;
    left: 0px;
    max-height: 75%;
    margin:auto

} */
.navbarDown{
  margin-top: 12px;
  /* margin-left: 10px; */
  transform: translate(-50%,-50%);
}
input[type="search"]::placeholder {
  /* Firefox, Chrome, Opera */
  text-align: center;
}
.flagWrapper {
  width: 3rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nav-link{
  position: relative;
}
.logoKisi {
  margin-left: 20%;
  max-width: 40%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.logoKisiWrapper {
  text-align: middle;
}

.logoKisi:hover {
  cursor: pointer;
}

.menuWrapper{
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}

/* .navbar {
  background-color: transparent !important;
} */

.nav-link {
  font-weight: 500;
  letter-spacing: 0.1 !important;
  position: relative;
}
.bi-chevron-down{
  position: absolute;
  left: 95%;
  top: 50%;
  transform: translate(-50%,-50%);
}
.nav-item {
  letter-spacing: 3;
}
.navbarWrapper {
  display: grid;
  grid-template-columns: 80% 20%;
  padding-top: 2rem;
}
.navbar-nav {
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-evenly;
  float: left;
}
.nav-item {
  border-style: none;
  background-color: transparent;
}
.show {
  /* height: auto !important; */
}
.nav-link {
  color: #003c64;
}
.navbarMobileMenu {
  text-align: center;
}
.mobile-nav-button{
  margin-left:5px
}
.mobileMenuImg{
  object-fit: fill !important;
  filter: invert(42%) sepia(0%) saturate(0%) hue-rotate(32deg) brightness(102%) contrast(91%);
  height:1.3rem;
}
.branchPosition{
  left: -27px;
}
.logoKisiMobile {
height: 0.9rem;
  margin: auto;
  margin-left: 17px;
  margin-top:-5px;
  /* margin-top: -0.2rem; */
  /* border-radius: 50%; */
  /* margin-left: -4rem; */
}
.navbarMobileDownload{
  margin-top: 0 !important;
  margin-left: auto;
  margin-right: 3%;
  padding-top: 1px!important;
  padding-bottom: 0px !important;
  font-size:0.73rem !important;
  border-width: 2.5px !important;
  padding-left: 0.75rem !important;
  padding-right: 0.75rem !important;
}
.flagContainer {
  margin-right: 2rem;

  right: 0px;
}
.flagImg {
  border: 2px groove #0060ad;
  border-radius: 50%;
  cursor: pointer;
}
.show{
  transition: 0.8s !important;
  height: 100vh !important;
  opacity: 0.2;
  background-color: tr;
}
.flagImgActive {
  width: 2rem;
}
.flagImgInactive:hover {
  width: 2rem;
}
.flagImgInactive {
  width: 1.5rem;
  transition: width 0.2s;
}
.mobileNavbarMenu{
  height: 0;
  /* opacity: 0; */
  /* display: none; */
visibility: hidden;
/* height: 80vh; */
background-color: white !important;
/* position: absolute; */
/* transition: height 0.5s; */
position: relative;
}
.mobileMenuWrapper{
  height: 100%;
  /* padding-top: 3rem; */
  position: relative;
  z-index: 3;
}
.navbar-toggler{
  border:none !important;
  padding: 0.25rem
 0.2rem !important;
}
.navbar-toggler:focus{
  outline: none;
  box-shadow: none !important;;
}
.mobileMenu{
  /* position: relative; */
    color: #666;
    /* display: none; */
    margin-left: 20px;
    padding: 0 14px;
    font-size: 16px;
    text-decoration: none;
    /* transition: .3s ease-in; */
    animation-timing-function: cubic-bezier(.36,.07,.19,.97);
    font-weight: 400;
    transform-style: preserve-3d;
    /* -webkit-backface-visibility: hidden;
    backface-visibility: hidden; */
    margin-top: 80%;
    transition:visibility 0s,margin 0.5s;
    position: absolute;
    bottom: 0;
    left:0;
    margin-bottom: 3%;
    margin: auto;
    text-align: center;
    width: fit-content;
    /* background-color: red; */
    z-index: 99;
}
.menu1{
  animation-duration: 1s;
}
.menu2{
  animation-duration: 9s !important;
}
.navbar-toggler {
}
.logoKisiWrapperMobile {
  /* text-align: center; */
  /* width: 80%;
  margin: auto; */
  height: 100%;
}
@media only screen and (max-width: 1500px) {
  .lowerNavbar {
    grid-template-columns: 45% 55%;
  }
  .logoKisi {
    max-width: 40% !important;
  }
  .navbarWrapper{
    grid-template-columns: 90% 10%;
  }
}
@media only screen and (max-width: 1300px) {
  .lowerNavbar {
    grid-template-columns: 45% 55%;
  }
  .navbarWrapper{
    grid-template-columns: 96% 4%;
  }
  .menuWrapper{
    padding-top: 0rem;
  }
  .logoKisi{
    max-width: 35% !important;
  }
  .navigationBar{
/* padding-top: 0.6rem; */
  }
}
@media only screen and (max-width: 1200px) {
  .lowerNavbar {
    grid-template-columns: 30% 70%;
  }
  .logoKisi{
    max-width: 60% !important;
  }

}
@media only screen and (max-width: 1000px) {
  .mobileNavbar {
    display: block !important;
  }
  .lowerNavbar {
    display: none !important;
  }
  .menuLogoMobile {
    display: flex;
    flex-wrap: inherit;
    align-items: center;
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    padding-left: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;
  }
  .navigationBar {
    padding-top: 0.9rem;
    padding-bottom: 0.9rem;
  }
  
  .mobile-navbar-menu{
    margin-top: 10%;
  }
 
}
@media only screen and (max-width: 768px) {


}
