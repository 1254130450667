.strukturOrganisasiImg{

    width: 100%;
    margin-bottom: 2rem;
    margin-top:7rem ;
}
@media only screen and (max-width: 768px) {
 .strukturOrganisasiImg{
     margin-top:4rem
 }
}
