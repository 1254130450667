.footer {
  left: 0;
  bottom: 0;
  width: 100%;

  text-align: center;
  min-height: 200px !important;
  /* -moz-box-shadow: 0 -5px 6px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0 -5px 6px rgba(0,0,0,0.5); */
  box-shadow: 0px 1px 9px rgba(0, 0, 0, 0.4);
}
.socmedFooterContainer1{
  display: none;
}
.twitFil{
  filter: invert(54%) sepia(9%) saturate(76%) hue-rotate(181deg) brightness(91%) contrast(88%);
}
.groupSelect{
  display: none;
}
.storeFooterDiv{
  justify-content: left;
}
.socmedFooterImg{
  width: 1.6rem;
  cursor: pointer;
  transition: 0.3s;
  /* filter: brightness(0%); */
  margin-left:0.4rem;
  margin-right:0.4rem
}
.downloadImgFooter{
  height: 1.75rem;
  /* margin-right: 1rem; */
  cursor: pointer;
}
.upperFooter {
  display: grid;
  grid-template-columns: 40% 60%;
  padding-top: 2rem;
  justify-content: space-between;
}
.logoKisi {
  margin-left: 20%;
  max-width: 40%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.logoWrapper {
  text-align: middle;
}

.logoKisiFooter{
width:15rem;
  filter: brightness(0) invert(1);
  margin-bottom: 1rem;
}

.footerMenuWrapper {
  display: flex;
}
.footerMenu {
  width: 50%;
}
.socmedWrapper {
  margin: auto;
}
.socmedContainer {
  /* display: flex; */
  /* width: 60%; */
  margin: auto;
}


.menu {
  text-align: left;
  /* font-family: TypoldRegular; */
  color: #6e6f72;
  padding-top: 10px;
  cursor: pointer;
  font-size: 0.8rem;
  /* letter-spacing: 1px; */
}
.copyrightMobile {
  display: none;
}
.lowerFooter {
  display: grid;
  grid-template-columns: 46% 44% 8%;
  justify-content: space-between;
  padding-bottom: 1.4rem;
  padding-top: 3rem;
}
.idc{
  height: 35% !important;
}
.lembagaImg {
height: 40%;
  /* filter: grayscale(50%); */
  transition: 0.3s ease;
  cursor: pointer;
  /* position: absolute; */

    bottom: 0;
}
.lembagaImg:hover {
  filter: none;
}
.lembagaWrapper {
  display: flex;
  margin-right: 3rem;
  /* grid-template-columns: 15% 16% 23% 23% 23%; */
  justify-content: space-around;
}
.ojk{
  height:3rem !important 
}
.idx{
 height:5rem !important
}
.lowerFooterLink:hover{
  text-decoration: underline;
  cursor: pointer;
}
.koreaGroupLink {

  cursor: pointer;
}
.koreaGroupLink:hover {
  color: #003c64;
}
.lembagaContainer {
height: 5rem;
display: flex;
  vertical-align: bottom;
  justify-content: center;
  position: relative;
  text-align: center;
  margin: auto;
}
.footerSmallText {
  /* font-family: TypoldRegular; */
  font-size: 0.6rem;
  color: #6e6f72;
  margin:auto;
  margin-top: 1rem;
  width: 80%;
}
.lowerFooterLinkContainer{
  display: flex;
  justify-content: flex-end;
  text-align: center;
  vertical-align: middle;
  display: flex;
  margin-right: 5rem;
  align-items: center;
  flex-wrap: wrap;
}
.bottomFooter {
  height: auto;
  background-color: #0060ad;
  display: grid;
  grid-template-columns: 50% 50%;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}
.noBorder{
  border: none !important;
}
.lowerFooterLink{
  padding-left: 2rem;
  padding-right: 1.5rem;
  border-left: 1px solid white;
  font-size: 0.8rem;
}
.copyrightBottom{
  text-align:left; 
  font-size: 0.7rem;
}
.lowerFooterLinkWrapper{
  height: fit-content;
  flex-wrap: wrap;
}
.addressFooter {
  margin-left: 16rem;
  text-align: left;
}
.headquarterTextFooter {
  font-size: 1.5rem;
  font-weight: 700;
}
.addressDetailText {
  cursor: pointer;

}
.addressDetailText.monsterratBold{
  cursor: pointer;
  /* white-space: nowrap; */
}
.addressDetailText:hover {
  color: #003c64;
}
.addressDetailFooter {
  font-size: 0.8rem;
  line-height: 2;
  text-align: left !important;

  margin-top: 0.5rem;
}
.socmed:hover {
  filter: brightness(0%);
}
.socmedText {
  font-size: 1.5rem;
  font-weight: 700;
  margin-bottom: 1.3rem;
}
#copyrightContainer {
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin: auto;
  height: auto;
  align-self: center;
  vertical-align: middle;
  width: 100%;
  margin-left: 10%;
}
.copyright {
  /* margin: auto; */
  text-align: "center";
  color: "white";
  margin: "auto";
  height: "auto";
  align-self: "center";
  vertical-align: "middle";
  font-size: 0.9rem;
  /* width: 80%; */
  margin-left: 20% !important;
}

.koreaGroup {
  /* color: white; */
  margin: auto;

  margin-top: 0.5rem;

  text-align: left;
  font-size: 0.8rem;
}

.groupMember {
  display: flex;
  grid-template-columns: 50% 50%;
  text-align: left;
 grid-gap: 3rem;
  margin: auto;
  color: grey;
  font-size: 0.8rem;
  line-height: 2.2;
  text-align: left !important;
  margin-top: 0.5rem;
  font-size: 0.8rem;
}
.socmedFooter{
  margin-top: 0.5rem !important;
  width: inherit;
}
.temukanTextFooter{
  display: inline-block;
  margin-bottom: 0.2rem;
  text-align: center;
}
.socmedFooterContainer{
  position: relative;
}
.socmedFooterWrapper{
  /* position: absolute; */
  bottom: 0;
  width: fit-content;
  margin-bottom: 0.1rem;
}
@media only screen and (max-width: 1700px) {
  .upperFooter {
    /* grid-template-columns: 35% 30% 30%; */
  }
  .addressFooter{
    margin-left: 15rem;
  }
}
@media only screen and (max-width: 1600px) {
  .lowerFooter{
    grid-template-columns: 30% 65% ;
  }
  .bottomFooter{
    grid-template-columns: 35% 65%;
  }
}
@media only screen and (max-width: 1500px) {
  .upperFooter {
    /* grid-template-columns: 35% 30% 30%; */
  }
  .addressFooter{
    margin-left: 11rem;
  }
 
}

@media only screen and (max-width: 1400px) {
  .lowerFooter {
    /* padding-top: 1rem; */
    /* grid-template-columns: 45% 55% ; */
  }
  .upperFooter {
    /* grid-template-columns: 40% 30% 30%; */
  }
  .bottomFooter {
    /* padding-bottom: 7rem; */
  }
  /* .groupMember {
    width: 85%;
    
  } */
  .addressFooter{
    margin-left:7rem
  }
  .logoKisiFooter{
    max-width: 12rem;
  }
  .lowerFooterLinkContainer{
    margin-right: 2rem;
  }
  
}
@media only screen and (max-width: 1300px) {
  .addressFooter{
    margin-left:2rem
  }
  .lowerFooterLinkContainer{
    margin-right: 0rem;
  }
}
@media only screen and (max-width: 1200px) {
 .bottomFooter{
  grid-template-columns: 25% 75%;
 }
 .copyright{
  margin-left: 10% !important;
 }
}
@media only screen and (max-width: 1100px) {
  .groupMember {
    /* width: 95%; */
  }
  .bottomFooter {
    /* display: flex; */
    /* flex-direction: column-reverse;; */
    padding: 0.5rem;
  }
#copyrightContainer{
  padding-left: 0.5rem;
}
}

@media only screen and (max-width: 1000px) {
  .lembagaWrapper{
    margin-right:0;
    width: 90%;
    margin: auto;
  }
  .lowerFooter {
    display: flex;
  }
  .groupSelect{
    display: block;
    width: 70%;
    padding-top: 1rem;
    font-size: 0.7rem !important;
  }
  .logoKisiFooter{
    max-width:9rem;
  }
  #copyrightContainer {
    /* display: none; */
  }
  .groupMember {
    display: none;
    width: 100%;
  }
  .upperFooter {
    display: block;
    padding-top: 0.7rem;
    position: relative;
  }
  .copyright {
    width: 100%;
    margin-left: 2rem !important;
  }
  .socmedFooterContainer1{
    display:block;
    margin-left: 2rem !important;
    padding-top: 1rem;
  }
  .socmedFooterContainer2{
    display:none
  }
  .socmedFooterWrapper{
    right: 0;
    margin-right: 1rem;
    margin-bottom: -1rem;
  }
  .lowerFooterLinkWrapper{
    width: 100%;
    justify-content: center;
  }
  .lowerFooterLink{
    width: 33%;
    margin-top: 0.5rem;
    font-size: 0.6rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .bottomFooter{
    grid-template-columns: 25% 50%;
    justify-content: space-between;
  }
  .followSocmedImgContainer{
    display: flex;
  }
}
@media only screen and (max-width: 768px) {
  .socmedFooterContainer1{
    display:block;
    margin-left: 0rem !important;
    padding-left: 0.8rem;
  }
  .lembagaContainer {
    margin-top: 2rem;
    display: flex;
  }
  .lowerFooterLink{
    font-size: 0.4rem;
  }
  /* .socmed {
    max-width: 70%;
  } */
  .socmedWrapper {
    width: 90%;
  }

  .lowerNavbar {
    display: none !important;
  }
  .mobileNavbar {
    display: block !important;
  }
  .addressFooter {
    margin: auto;
    text-align: center;
  }
  .addressDetailFooter {
    text-align: center;
    margin: auto;
    width: 100%;
    font-size: 0.52rem;
  }
  .addressFooter {
    margin: 0;
    width: 55%;
    margin-top: 1rem;
    padding-left: 0.8rem;

  }
  .menu {
    text-align: center;
  }
  .footerMenuWrapper {
    display: none;
  }
  
  .footerSmallText {
    display: none;
  }
  .lowerFooterContainer {
    margin-top: -5rem;
  }
  .socmedText {
    display: none;
  }
  .socmedContainer {
    margin-top: 2rem;
  }
  .bottomFooter {
    /* display: block; */
    padding: 0.5rem;
    grid-template-columns: 25% 60%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0rem;
   
  }
  #copyrightContainer {
    margin-left: 0;
    text-align: center;
  }
 
  .lembagaWrapper {
    grid-template-columns: 14% 16% 30% 23% 20%;
    width: 70%;
    margin: auto;
    /* grid-gap: 1rem; */
    /* margin-right: 5%; */
    text-align: center;
  }
  .lembagaImg {
    width: auto;
    height: 30% !important;
  }

  .socmedFooterContainer{
    /* display: none; */
  }
  .lowerFooterLinkContainer{
    margin-right: 0px;
    /* display: none; */
  }
  .lowerFooterLinkWrapper{
    width: 100%;
    display: flex;
    justify-content:right
  }
  .koreaGroup{
    max-width: 90%;
    margin: 0;
    font-size: 0.52rem;
  }
  .headquarterTextFooter{
    margin-bottom: 1rem;
    text-align: left;
    font-size: 0.9rem;
  }
  .logoKisiWrapper{
    text-align: left;
  }
  .logoKisiFooter{
    width: 5rem;
  }

  .downloadImgFooter{
    height: 1.1rem;
    margin-right: 1rem;
    cursor: pointer;
    margin: auto;
  }

  .followSocmedImgContainer{
    display:flex;
    width: 100%;
    justify-content: space-between;
  }
  .temukanTextFooter{
    font-size:0.9rem !important;
  }
  .socmedFooterImg{
    width: 1.2rem;
    cursor: pointer;
    transition: 0.3s;
    /* filter: brightness(0%); */

    margin-right:0.2rem
  }
  .idc{
    height: 25% !important;
  }
  .ksei{
    height: 25% !important;
  }
  .sipf{
    height: 25% !important;
  }
  .copyright{
    margin-left:0.8rem !important;
   }
.copyrightFooter{
  font-size: 0.35rem;
}
}
