.leftSide {
  padding-right: 3%;
}
.path {
  display: flex;
  width: 66%;
  /* font-family: StemRegular; */
  height: 100%;
}
.appPageLocation {
  justify-content: center !important;
}
.rightSide {
  padding-left: 3%;
}
.subPathContainer {
  display: flex;
  width: 90%;
  text-align: center;
  justify-content:center;
  margin: auto;
  row-gap:1.2rem;
  flex-wrap: wrap;
}
.subPathWrapper {
  /* height: 100%; */
  top: auto !important;
  background-color: white;
  padding-top: 5rem;
  z-index: 500 !important;
  transition: 0.3s;
  /* box-shadow: 0px 4px 14px rgb(0 0 0 / 7%);  */
}
.subPathSpacer {
  height: 116px;
}
.subPathButton {
  font-size: 14px;
  /* transition: 0.3s; */
  background-color: transparent;
  border: none;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}
.subPath {
  font-size: 14px;
  /* transition: 0.23s ; */
  /* background-color: #f2f2f2; */
  border: 1px solid #787878;
  /* width: 18.5%; */;
  border-radius: 12px !important;
  padding: 0.35rem 2rem 0.33rem 2rem;
  color: #787878;
}
.subPathProduk{
  width: 15% !important;
}
.subPath:hover {
  color: white;
  cursor: pointer;
  background-color: #0067ba;
  /* font-family: TypoldBold; */
  color: white !important;
  border-color: #0067ba;
  text-shadow: 0px 0px 1px white;
}
.subPathActive {
  background-color: #0067ba;
  /* font-family: TypoldBold; */
  color: white !important;
  text-shadow: 0px 0px 1px white;
  cursor:pointer !important;
  border-color: #0067ba !important;
  transition: none;
}
/* .subPathButton:hover {
  color: white;
  cursor: pointer;
  background-color: #0061ad;
  font-family: TypoldBold;
  color: white !important;
  font-weight: 900;
} */
.pageLocationWrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  /* margin-top: 0.3rem; */
  /* margin-bottom: 1rem; */
}
.subPathButtonActive {
  background-color: #0061ad;

  color: white !important;
  font-weight: 900;
  cursor: initial !important;
}
.leftSide {
  cursor: pointer;
}

@media only screen and (max-width: 1500px) {
.subPathContainer {width: 92%}}
@media only screen and (max-width: 1400px) {
  .subPathContainer {
    width: 95%;
  }
}
@media only screen and (max-width: 1000px) {
  .subPathContainer {
    width: 95%;
  }
  .subPathSelect {
    width: 60%;
    margin: auto;
    margin-top: 2rem;
    padding-left: 2rem;
    /* position: absolute; */
    left: 0;
    right: 0;
position: relative;
    margin-left: auto;
    margin-right: auto;

    z-index: 50;
  }
}
@media only screen and (max-width: 768px) {
  .subPathContainer {
    width: 100%;
    padding: 10px;
    /* display: grid;
    grid-template-columns: 50% 50%; */
  }
  .subPathWrapper {
    margin-top: 0rem;
  }
  .subPath {
    width: 90%;
    font-size: 8px;
    margin-top: 1rem;
  }
  /* #Syariah{
    margin-left: 50% !important;
  } */
  .mx-5 {
    margin-left: 1rem !important;
  }
  .mobileNavbar {
    display: block !important;
  }
  .leftSide {
    padding-right: 5% !important;
  }
  .rightSide {
    padding-left: 5% !important;
  }
  .path {
    font-size: 0.8rem;
    width: 90%;
  }

  .pageLocationWrapper {
    display: block;
  }
  .subPathButton {
    font-size: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .subPathWrapper {
    margin-right: 0px;
    padding-top: 1rem;
  }
  .productSubPath {
    display: none;
  }
  select[selected] {
    background-color: red !important;
  }
  option:not(:checked) {
    background-color: white;
    color: #000;
  }
  select {
  }
  option[selected] {
    border-radius: 100px;
    background-color: red !important;
  }
  .form-select {
    border-radius: 20px;
  }
  .subPathSelect {
    width: 60%;
    margin: auto;
    margin-top: 2rem;
    padding-left: 2rem;
    /* position: absolute; */
    left: 0;
    right: 0;
position: relative;
    margin-left: auto;
    margin-right: auto;

    z-index: 50;
  }
  .subPathProductSpacer {
    display: none;
  }
}
