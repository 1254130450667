.mainArticleContainer {
  /* height: 24rem; */
  /* border-radius: 0.5rem; */
  background-repeat: "no-repeat" !important;
  /* width: 90%; */
  background-position: center;
  margin: auto;
  vertical-align: bottom;
  margin-top: 2rem;
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 0.6rem;
  position: relative;
}
.articlePageContainer {
  overflow-x: visible;
}
.searchWrapper {
  /* display:none !important; */
}
.gridNewsContainer {
  width: 85%;
  margin: auto;
  flex-wrap: wrap;
}
.edukasiThumb img {
  display: none;
}
.news-tag-button{
  transition: 0.2s;
}
.news-tag-button:hover{
  background-color: #0071bf !important;
  color: white;
}
.edukasiContent img {
 width: 100%;
}
.edukasiListContainer {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
}
.edukasiThumbDiv {
  width: 16rem;
  flex-wrap: wrap;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 33.3333%;
}
.edukasiThumb {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.clamp2{
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical; 
}
.demo-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.mainArticleHeading {
  position: absolute;
  bottom: 10px;
  left: 30px;
  color: white;
}
.edukasiThumbDiv:hover .thumbTitle {
  color: #009dc8;

}
.edukasiThumbDiv:hover{
  cursor: pointer;
}
.blogLeft {
  text-align: right;
  /* border-right: 0.125rem solid 
  #6d6e6e; */
}
.sideImgContainer {
  /* height:10.625rem !important;
  width: 10.625rem !important; */
  /* width: 156px;
  display: block;
  margin-left: auto;
  margin-right: auto; */
}
.sideNewsTitle {
  padding-left: 0.3rem;
  margin-bottom: 2rem;
}
.sideImgWrapperBottom {
  height: 100%;
  width: 100%;
}
.searchWrapper {
  margin: auto;
  width: 30%;
  display: grid;
  grid-template-columns: 80% 20%;
}
.searchButton {
  color: white;
  font-size: 1rem;
  background-color: #0071bf;
  border-color: #0071bf;
  border-style: none;
  max-height: 100%;
  height: 2rem;
  justify-content: space-between;
  padding-left: 1rem;
  padding-right: 2rem;
}
.searchBox:focus {
  outline: none !important;
  border: 0.125rem solid #707070 !important;
}
.sideArticleContainer {
  width: 100%;
  height: 50%;
  display: grid;
  grid-template-columns: 25% 75%;
}
.sideContainer {
  margin-top: 2rem;
  transition: 0.2s;
  cursor: pointer;
  margin-bottom: 0.6rem;
  padding-left: 2.75rem;
}
.searchBox {
  /* background-color: #e0e1e3; */
  border: none;
  width: 90%;
  margin: auto;
  height: 2rem;
  border: 0.125rem solid #707070;
  padding-left: 1rem;
  /* background-image: url("../../assets/search.png");
  background-size: 17px;
  background-repeat: no-repeat;
  background-position: 3% 50%; */
  /* text-align: center; */
}
.searchImg {
  max-height: 50%;
}
.mainNewsTitle {
  color: white;
  font-size: 2rem;
  padding-left: 2rem;
  padding-right: 2rem;
}
.topArticleContainer {
  display: grid;
  grid-template-columns: 49% 51%;
  width: 85%;
  margin: auto;
  justify-content: space-between;
  margin-bottom: 4rem;
  margin-top: 4rem;
}
.gridArticleWrapper {
  width: 33.3%;
  cursor: pointer;
  padding-left: 0.7rem;
  padding-right: 0.7rem;
  margin-top: 2rem;
}
.titleBottom {
  margin-bottom: 0rem !important;
  padding-top: 1rem !important;
}
.smallImageContainer {
  height: 17.5rem;
  background-size: contain;
  margin-top: 2rem;
  /* border-radius: 2rem; */
  background-position: center;
}
.lainnyaButton {
  padding-left: 7rem !important;
  padding-right: 7rem !important;
  border-radius: 14px;
  margin-bottom: 10rem;
}
.mainArticleSourceDate {
  font-size: 0.8rem;
  padding-left: 2rem;
  padding-bottom: 4rem;
}

.gridArticleSource {
  padding-right: 0.6rem;
}
.gridArticleDate {
  justify-content: flex-end;
  padding-left: 0.6rem;
}
.articleInfoContainer {
  margin-top: 2rem;
  padding-left: 0.2rem;
}
.artikelSubtitle {
  max-width: 25%;
}
.mostPopularContainer {
  background-color: #0060ad;
  width: 83%;
  height: auto;
  margin: auto;
  border-radius: 30px;
  margin-top: 3rem;
}
.articleContainer {
  width: 90%;
  height: 28rem;
  cursor: pointer;
  margin: auto;
}
.mostPopularContentPreview {
  font-size: 0.7rem;
  font-family: TypoldThin;
}
.readMoreMostPopular {
  font-size: 0.6rem;
  font-family: TypoldRegular;
}
.readMoreMostPopular:hover {
  font-family: TypoldBold;
}
.floatingArticleTitle {
  font-size: 1rem;
  margin-left: 1.5rem;
  width: 90%;
}
.articleImage {
  background-color: #0060ad;
  height: 19rem;
  border-radius: 0.5rem;
  background-size: contain;

  background-position: center;
}
.mostPopularHeadline {
  margin: auto;

  text-align: center;
  padding-top: 1.5rem;
  font-size: 2rem;
  padding-bottom: 2rem;
}
.articleTitle {
  font-size: 1.2rem;
  color: #003c64;
  margin-top: 1rem;
}
.rnaTable {
  width: 60%;
  margin: auto;
  margin-top: 2rem;
  /* border:1px solid r  */
}
.rnaRow {
}
.namaRow {
  width: 50%;
}
.tanggalRow {
  width: 25%;
}
.mostPopularFixedTitle {
  color: white;
  font-size: 1rem;
  margin-right: 5rem;
}
.mostPopularNumber {
  color: white;
  font-size: 3rem;
}
.numberButtonDisabled {
  pointer-events: none;
  color: #707070 !important;
  border-color: #707070 !important;
}
.rnaPath {
}
.numberButtonDisabled:hover {
  background-color: white !important;
}
.numberButton {
  border-radius: 50%;
  width: 2.75rem;
  height: 2.75rem;
  color: #0071bf;
  background-color: white;
  border: 3px solid #0071bf;
  transition: 0.2s;
}
.numberButton:hover {
  background-color: #0071bf;
  color: white;
}
.numberButtonActive {
  background-color: #0071bf;
  color: white;
}
.downloadImgRiset {
  height: 1.75rem;
}
.floatingHeadline {
  color: white;
  font-size: 1.5rem;
  text-align: center;
  padding-top: 2rem;
  margin-bottom: 2rem;
}
.mostPopularCol {
  display: grid;
  grid-template-columns: 10% 90%;
  padding-bottom: 2rem;
  cursor: pointer;
}

.popularNewsContainer {
  width: 90%;
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;
}
.floatingNewsNumber {
  margin-left: 1rem;
  font-size: 3rem;
}
.mostPopularContainerz {
  background-color: #0060ad;
  width: 83%;
  height: 10rem;
  margin: auto;
  border-radius: 3rem;

  position: -webkit-sticky; /* Safari & IE */
  position: sticky;
  top: 20vh;
}
.allNewsContainer {
  width: 90%;
  margin: auto;
}
.allArticle {
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;
  width: 85%;
}
.allArticleWrapper {
  margin-top: 2rem;
}
.floatingContainer {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  width: 50%;
}
.sideArticleContainer.bottom-vertical {
  display: flexbox;
}
.floatingList {
  height: 40rem;
  background-color: black;
  width: 90%;
  position: sticky;
  position: -webkit-sticky !important; /* Safari & IE */
  top: 5vh;
  margin: auto;
  margin-top: 2rem;
  border-radius: 1rem;
  margin-bottom: 10rem;
  padding-right: 1rem;
  padding-left: 1rem;
}

.articleSourceContainer {
  display: grid;
  grid-template-columns: 50% 50%;
}
.promoMenuPageContainer {
  margin-bottom: 15rem;
}
.promoMenuPageWrapper {
  width: 77%;

  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.promoMenuWrapper {
  width: 46%;
  /* height: 20rem; */
  /* background-color: red; */
  margin-top: 5rem;
  cursor: pointer;
}
.promoTitle {
}
.blogTitle:hover{
  color: #009dc8;
}
.promoThumbnail {
  width: 100%;
  /* height: 10rem; */
  /* height: 25rem; */
  background-repeat: no-repeat;
  /* background-attachment: fixed; */
  background-size: contain;
  background-position: center;
}
@media only screen and (max-width: 1400px) {
  .mainArticleContainer {
    /* height: 30rem; */
  }
  .w60.mx-auto.mt-5 {
    width: 75%;
  }
  .rnaTable {
    width: 75%;
  }
  .smallImageContainer {
    height: 10rem;
    /* width: 95%; */
    margin: auto;
    margin-top: 1rem;
  }
  .articleImage {
    height: 13rem;
  }
  .articleContainer {
    height: 23rem;
  }
  .allArticle {
    /* grid-template-columns: 55% 45%; */
  }
  .floatingList {
    height: 45rem;
  }
  .searchWrapper {
    width: 28rem;
  }
}

@media only screen and (max-width: 1300px) {
}

@media only screen and (max-width: 1150px) {
  .floatingList {
    display: none;
  }
  .allArticle {
    display: block;
  }
  .searchWrapper {
    width: 26rem;
  }
  .allArticleWrapper {
    display: grid;
    grid-template-columns: 50% 50%;
  }
  .gridArticleWrapper {
    grid-template-columns: 45% 45%;
    justify-content: space-around;
  }
  .popularNewsContainer {
    display: block;
  }
  .artikelSubtitle {
    max-width: 50%;
  }
}
@media only screen and (max-width: 1000px) {
  .w60.mx-auto.mt-5 {
    /* width: 95%; */
  }
  .none1000{
    display: none;
  }
  .block1000{
    display: block !important;
  }
  .w1001000{
    width: 100% !important;
  }.mw1001000{
    max-width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .smallImageContainer {
    height: 12rem;
  }
  .articleInfoContainer {
    margin-top: 1rem;
  }
  .w60.mx-auto.mt-5 {
    width: 95%;
  }
  .rnaTable {
    width: 95%;
  }
  .searchWrapper {
    display: none;
  }
  .topArticleContainer {
    display: none;
  }
  .gridNewsContainer {
    display: block !important;
    width: 95%;
  }
  .gridArticleWrapper {
    width: 100%;
  }
  .mainArticleContainer {
    /* height: 15rem;
    margin-top: 1rem;
    border-radius: 0.5rem; */
  }
  .artikelSubtitle {
    max-width: 80%;
  }
  .mainNewsTitle {
    font-size: 1rem;
  }
  .mainArticleSourceDate {
    padding-bottom: 2rem;
  }
  .gridArticleContainer {
    display: block;
  }
  .gridArticleWrapper {
    display: block;
  }
  .popularNewsContainer {
    display: block;
  }
  .numberButton {
    width: 1.5rem;
    height: 1.5rem;
  }
  #blogSpacer {
    /* display: none !important; */
  }
  .mostPopularFixedTitle {
    margin-right: 0rem;
    margin-left: 1rem;
  }
  .mostPopularHeadline {
    font-size: 1.5rem;
  }
  .floatingList {
    display: none;
  }
  .allArticle {
    margin-top: 1rem;
    display: block;
    width: 90%;
  }
  .articleContainer {
    width: 90%;
    margin: auto;

    height: 17rem;
  }
  .articleImage {
    height: 10rem;

    margin: auto;
  }
  .allArticleWrapper {
    display: block;
  }
  .articleTitle {
    font-size: 0.8rem;
  }
  .articleSourceContainer {
    font-size: 0.6rem;
  }
}
