m
.margin {
  width: 75rem;
  margin: auto;
}
.marginImg {
  width: 100%;
}
.marginBannerTitle{
  font-size:4rem
}
.syaratMargin {
  padding-left: 2rem;
}.marginContent {
  width: 55%;
  line-height: 2.4;
  margin: auto;
  margin-bottom: 2rem;
}
.marginImgDiv{
  left: 0;
    margin-left: 4%;
    margin-bottom: 1%;
}
.rumus {
  white-space: pre-line;
  display: grid;
  grid-template-columns: 48% 4% 48%;
  margin: auto;
}
.marginImgContainer{
  /* height: 50rem !important;  */
  text-align: right;
  margin-bottom: 2rem !important;
}
.marginImg{
/* height: 80% !important; */
width: 69% !important;
margin-left: auto;
margin-top:-2rem;
margin-right: -2.5rem;
}

.marginBannerText{
  text-align: left !important;
 margin-left: 3% !important;
 margin-bottom: 15% !important;

}
.marginDownloadDiv{
  margin-bottom: 5% !important;
  margin-left: 3% !important;
}
.marginCheck{
  height: 2rem;
}
.marginBannerTitle{
  line-height: 1 !important;
}
.divided {
  width: 17rem;
  text-align: center;
}
.marginAccImg{
  max-width: 100%;
}
@media only screen and (max-width:1700px) {
  .marginBannerTitle {
    font-size: 3.5rem;
  }
  }
@media only screen and (max-width:1500px) {
.marginBannerTitle {
  font-size: 2.8rem;
}
}
@media only screen and (max-width:1280px) {
  .etfContent {
    width: 90%;
  }
  .margin {
    width: 90%;
  }
  .marginContent{
    width: 80%;
  }
  .marginBannerTitle {
    font-size: 2.4rem;
  }
  .marginDownloadDiv{
    margin-bottom: 2% !important;
  }
}

@media only screen and (max-width: 768px) {
  .etfContent {
    width: 90%;
  }
  .margin {
    width: 90%;
  }

  .marginAccImg{
    width: 100%;
  }
  .table{
    font-size: 0.8rem;
  }
  .divided{
    width: auto;
  }
  .brdiv{
    display: none;
  }
  .marginContent{
    width: 90%;
    margin-top: 6rem;
  }
  .marginBannerTitle{
  font-size: 1.5rem !important;
  }
  .marginDownloadDiv{
    /* margin-top: 10% !important; */
    bottom: 0;
    margin-bottom: -25% !important;
    margin-left: 2rem !important;
  }
  .marginBannerText{
    margin-bottom: 1% !important;
    margin-left: 7% !important;
  }
  .marginPersenImg{
    margin-top: 2rem;
    font-size: 0.5rem !important;
  }
  .marginImgContainer{
    /* padding-top: 7.2rem !important; */
  }
  .marginCheck{
    height: 0.75rem;
  }
}
