.promoImg {
  border-radius: 0.4rem;
}
.faqTitle{
  /* text-align: center; */
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: auto !important;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
  
}.home-popup > .swal2-html-container {
  margin: 0px !important;
}
.home-popup {
  padding: 0px !important;
  width: auto; background-color: transparent;
}
.popupImg{
  width: 30vw;
 
}
.faqContainer {

  margin: auto;
}
.mySwiper{
  width: 55%;
  /* box-shadow: 54px 1px 95px -5px rgba(255,255,255,0.7) inset; */
}
.branchCity:hover + .faqContent,
.branchCity:hover #jakartaAddress {
  height: 8em;
  color: #6e6f72;
  line-height: 1.5;
  border-color: #0060ad;
  border-top: none;
}
.faqDiv {
  margin-top: 7rem;
  width: 100%;

}

.faqContent {
  border: 3px solid;
  border-color: transparent;
  color: transparent;
  height: 0px;
  width: 20rem;
  margin: auto;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
  width: 100%;
  transition: all 0.5s ease-in-out;
  /* position: absolute; */
}

.faqWrapper {
  height: auto;
  max-height: 90px;
  border-radius: 15px;
  transition: max-height 400ms ease-in-out;
  width: 55%;
  /* position: absolute; */
  z-index: 1;
  background-color: white;
  border:1px solid #707070;
  margin: auto;
  margin-bottom:2rem;
  padding-bottom: 1.7rem;
  overflow: hidden;
  cursor: pointer;
  /* transition: max-height 0.25s ease-in; */
}
.faqText {
  margin-bottom: 4rem;
}
.homePage{}
.faqWrapper:hover {
  height: auto;
  max-height: 275px;
  z-index: 2;
  border-color: #0071bf;
}
.faqWrapper-long:hover{
  max-height: 1000px !important;
}
/* .faqWrapper-long{
  max-height: 90px !important;
} */
.faqSpacer {
  height: 125px;
}
.vertical-center {
  /* display: flex;
  align-items: center;
  justify-content: center; */
}
.faqTitle {
  height: 90px;
  display: flex;

}
.faqAnswer {
  opacity: 0;
  transition: 0.3s;
  padding-right: 3rem;
  padding-left: 3rem;
  padding-top: 0rem;
}
.faqAnswerContainer {
  margin-top: -1rem;
}
.textApp2nd{
  width: 70vw!important;
}
.appContainer2{
  margin-top: -3rem;
}
.faqWrapper:hover .faqAnswer {
  opacity: 1;
}

.faqArrowImg {
  max-height: 40%;
  transition: transform 0.4s ease-in-out;
  filter: invert(29%) sepia(57%) saturate(2807%) hue-rotate(186deg)
    brightness(90%) contrast(101%);
}

.faqWrapper:hover .plusDiv {
  transform: rotate(90deg);
  
}
.faqWrapper:hover .plus{
  max-height:0;
      opacity:0;
}
.plusDiv{
  height: 75px;
  width: 75px;
  text-align: center;
  transition: transform 0.4s ease-in-out;

}
.faqIndicator {
  color: #707070;;
  background: white;
  width: 50px;
  height: 50px;
  border: 0;
  font-size: 1.5em;
  position: relative;
}

.faqIndicator span {
  position: absolute;
  transition: 300ms;
  background: #707070;;
  border-radius: 2px;
}

/* Create the "+" shape by positioning the spans absolutely */
.faqIndicator span:first-child {
  top: 25%;
  bottom: 25%;
  width: 10%;
  left: 45%;
}

.faqIndicator span:last-child {
  left: 25%;
  right: 25%;
  height: 10%;
  top: 45%;
}

/* Morph the shape when the .faqIndicator is hovered over */
.faqWrapper:hover span {
  transform: rotate(90deg);
background-color: #0071bf;
}

.faqWrapper:hover span:last-child {
  left: 50%;
  right: 50%;

}
.plus{
transition: 0.4s;
}
.minus{
  display: none;
}
.vertical-center {
  /* position: relative;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%); */
}iframe-k
.appText {
  /* margin-bottom: -10rem; */
}
.selengkapnyaButton{
  background-color: #0071bf;
  color: white;
  border-radius: 12.5px;
  border:none;
  transition: 0.2s;
}
.bannerGif{
  position: absolute;
  height: 9rem;
  right: 0;
  margin-top: 22%;
  margin-right: 30%;
}
.swiperLangkah{
  width: 55%;
  /* background-image : linear-gradient(to bottom, 
  rgba(255,255,255, 0), 
  rgba(255,255,255, 1) 90%); */
  /* box-shadow: inset -350px 0 100px 0 #FFF; */

}
.swiperLangkah:before{
  content: "";
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255,255,255,0) 87%, rgba(255,255,255,1) 100%);
  width: 100%;
  height: 100%;
}
.swiperLangkah:after{
  content: "";
  position: absolute;
  z-index: 100;
  bottom: 0;
 right: 0;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(255,255,255,0) 87%, rgba(255,255,255,1) 100%);
  width: 100%;
  height: 100%;
}

.slideWrapper{
  width: 90%;
  margin: auto;
}
.testiSpacer{
  margin-top: 0 !important;
}
.slideLangkahImg{

  width: 100%;
}
.slideContainerLangkah{
  text-align: center;
}
.selengkapnyaButton:hover{
  box-shadow: 0 1px 10px rgb(0 113 191);
}
.pelajariFaqButton{
  padding-top: 0.7rem;
  padding-bottom: 0.7rem;
  max-height: 6rem;
}
.pelajariFaq{
  margin-left: auto;
  margin-right: 3rem;
}
.appTextContainer{
  padding-left: 1rem;
  padding-right: 1rem;
  width: 100%;
}
.appTextWrapper{
  width: 100%;
}
.landingContainer {
  width: 100%;
  height: 55rem;
  margin: auto;
  /* box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.15);
  -webkit-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.15);
  -moz-box-shadow: 0px 10px 5px 0px rgba(0,0,0,0.15); */
  background-size: cover;
  background-repeat: "no-repeat" !important;

  background-position: right bottom;
transition: 1s ease-in-out;
  vertical-align: bottom;
  z-index: 2;
}
.newsContainerHome {
  cursor: pointer;
}
.landingContent {
  width: 40%;
  margin-left: 12%;
  padding-top: 10%;
}
.sliderSmall{
  width: 90%;
  margin: auto;
}
.landingBigText {
  font-size: 3rem;
}

.landingButton {
  border: 4px solid white;
  background-color: transparent;
  color: white;
  /* padding-left: 1.4rem;
  padding-right: 1.4rem; */
  margin-top: 1rem;

  font-size: 1.9rem;
  transition: 0.3s;
}
.landingButton:hover {
  color: #369de6;
  background-color: white;
}


.landingImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.appHeaderText {
margin: auto;
text-align: center;
}
.appHome{

  display: flex;
  /* grid-template-columns: 60% 40%; */
  text-align: center;
  align-items: center;
  height: 47rem;
  width: 50%;
  margin: auto;

    justify-content: center; /* center items vertically, in this case */
}
.kenapaWrapper {
  display: grid;

  width: 100%;
  margin: auto;
  margin-top: 3rem;
  margin-bottom: 6rem;
}
.kenapaLeftContainer {
  background-size: cover;
  background-repeat: "no-repeat" !important;
  z-index: -1;
  background-position: center;
  margin-top: -200px;

  vertical-align: bottom;
}
.koins3dHandImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.kenapaRightContainer {
  margin: auto;
}
.kenapaImageWrapper {
  height: 30%;
  width: 55%;
  display:grid;
  margin: auto;
  grid-template-columns: 25% 25% 25% 25%;
}

.kenapaImageContainer:hover  .reksadanaDescriptionContainer{
  visibility: visible;
  opacity: 1;
}
.descriptionHome{
  margin-top: -4.5rem !important;
  text-align: left;
  width: 150% !important;
  margin-left: -25% !important;
}
.kenapaImageContainer {
  width: 100%;
  transition: transform 0.2s;
  cursor: pointer;
}
.kenapaBigText {
  height: 10%;
  font-size: 2.5rem;
  margin-top: 3rem

  ;
      margin-bottom: 3rem;
}
.kenapaImg {
  width: 70%;
  margin: auto;
}
.kenapaSmallText {
  /* font-family: GigaSansBlack; */
  color: #0060ad;
  /* margin-left: 4%; */
  /* margin-top: -15%; */
  font-size: 2rem;
}

.thirdSectionContainer {
  height: 40rem;
  width: 100%;
  margin: auto;
  margin-top: 4rem;
  background-size: cover;
  margin-bottom: 2rem;
  background-position: center;
}

.text {
  color: #f2f2f2;
  font-size: 15px;
  padding: 8px 12px;
  position: absolute;
  bottom: 8px;
  width: 100%;
  text-align: center;
}
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

.galleryImage {
  max-width: 100%;
  max-height: 100%;
}

.firstSection {
  display: grid;
  grid-template-columns: 60% 40%;
}

.fill {
  max-width: 100%;
}
.firstSectionLeft {
  margin: auto !important;
}

.registration-button {
}

.grid-30-70 {
  display: grid;
  grid-template-columns: 30% 70%;
}
.mobileAppContainer {
  box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.57);
  display: grid;
  grid-template-columns: 20% 80%;
  width: 90%;
  margin: auto;
  border-radius: 4rem;
  justify-content: center;
  align-items: center;
  align-self: center;
  vertical-align: middle;
  margin-top: 2rem;
  cursor: pointer;
  transition: 0.3s;
}
.appButton {
  border: 2px solid white;
  background-color: #0060ad;
  color: white;
  padding-left: 3.5rem;
  padding-right: 3.5rem;
  padding-top: 0.1rem;
  padding-bottom: 0.1rem;
  margin-top: 2rem;
  font-size: 1rem;
  transition: 0.2s;
}
.appButton:hover {
  color: #0060ad;
  background-color: white;

}
.mobileAppTitle {
  font-size: 3rem;
}
.mobileAppContainer:hover {
  background-color: #0060ad;
  color: white;
}

.mobileAppSpacer {
  margin-top: 3rem;
}
.mobileImgContainer {
  text-align: center;
  padding: auto;
  align-items: center;
}
.bigButton {
  align-self: "center";
  vertical-align: "middle";
  margin: auto;
}
.width60 {
  width: 60%;
}
.mobileLogo {
  width: 80%;
  vertical-align: middle;
  margin: auto;
  padding-bottom: 1rem;
}

.center {
  justify-content: center;
  align-items: center;
}
.blue-btn {
  background-color: #0071bf;
  border-style: none;
  color: white;
  /* height: 2rem; */
  /* width: 10rem; */
  border: 3px solid #0071bf;
}

.justifyFlex {
}

.flexStart {
}
.flexEnd {
}

.container3 {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-around;
  width: 100%;
}
.promoContainer {
  display: grid;
  grid-template-columns: 31% 31% 31%;
  justify-content: space-around;
  width: 90%;
  margin: auto;
}
.width77 {
  width: 77%;
}

.whySection {
  width: 100%;
  text-align: center;
  background-color: #edeeef;
}

.newsTitle {
  color: #464749;
  font-size: 0.8rem;
  height: 3rem;
}

.downloadImg {
  height: 40px;
}

.mulaiButton {
  border: none;
  height: 40px;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-family: StemRegular;
  border-radius: 5px;
  font-size: 22px;
  color: #0060ad;
}

.newsImageWrapper {
  height: 14rem;
  background-position: center;
  border-radius: 0.4rem;
  margin-top: 1rem;
}

.img100 {
  width: 100%;
}

.newsSection {
  width: 90%;
  margin: auto;
  margin-bottom: 5rem;
}
.smallButton {
  border-radius: 30px;
  padding-right: 5rem;

  /* padding-left:35px;
  padding-right:35px */
}
.articleButtonContainer {
  margin-top: 5rem;
}
.articleButton {
  border: 3px solid #0060ad;
  background-color: transparent;
  transition: 0.3s;
}

.articleButton:hover {
  background-color: #0060ad;
  color: white;
}
.homeArticleSourceContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  font-size: 0.72rem;
}

.promoSection {
  width: 80%;
  padding: 3rem;
  margin: auto;
  box-shadow: 1px 2px 21px -2px rgba(0, 0, 0, 0.69);
  -webkit-box-shadow: 1px 2px 21px -2px rgba(0, 0, 0, 0.69);
  -moz-box-shadow: 1px 2px 21px -2px rgba(0, 0, 0, 0.69);
  margin-bottom: 3.4rem;
}
.biayaTransaksiContainer {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 6rem;
 
}
.biayaTitleContainer {
  /* height: 30%; */
}
.biayaTransaksiWrapper {
  /* box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48);
  -webkit-box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48);
  -moz-box-shadow: -2px 1px 0px 3px rgba(204, 196, 196, 0.48); */
  border:5px solid #0071bf;
  /* border-radius: 50px; */
  width: 55%;
  margin: auto;
  /* height: 38rem; */
  /* height: 39rem; */
}
.biayaTransaksiSpacerCenter{
  height: 21rem;
  vertical-align: middle;
  display: flex;
  justify-content: space-between;
 
  display: flex !important;
  width: 100%;
  align-items: center !important;
  /* padding-top: 6rem; */


  justify-content: space-between;
  align-items: center;
  margin: auto !important;
}
.biayaPersen {
  color: #0060ad;
  font-family: "Montserrat", sans-serif;
  font-weight: 900; /* Black */
  font-size:8rem;
}
.biayaTitle {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-size: 2.5rem;
  margin-top: 2rem;
}
.feeText {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-size: 1.6rem;
}
.biayaTransaksiGridContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  /* height: 55%; */
  width: 100%;
}
.sliderSmall {
  font-size: 1.2rem;
}
.swiperContainer {
  width: 100% ;
  margin: auto;
  margin-top:3rem;
}
.swiperContainerNgobrol {
  /* width: 80% !important; */
  width: 95%;
  margin: auto;
}
.appBlueBackground {
  height: 30rem;
  background-color: #0060ad;
  margin-top: 4rem;
  margin-bottom: 8rem;
  z-index: -1;
}
.appContent {
  margin-top: -46rem;
  height: 46rem;
  z-index: 2;
}
.appSection {
  margin-top: 14rem !important;
}
.appContentWrapper {
  display: flex;
  width: 100%;
}
.kisiMobileDiv {
  flex-direction: row-reverse;
}
.appLeftUpper {
  width: 100%;
  height: 8rem;
}
.appContentLeft {
  width: 70% !important;
  /* height: 38rem; */
  /* height: 30rem; */
  height: 46rem;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.appLeftLower {
}
.appLeftLowerWrapper {
  margin: auto;
  color: white;
}
.appContentText {
  margin-bottom: 15px;
}
.appBigText {
  font-size: 2.5rem;
}
/* .appContentRight.kisiMobileContent{
 padding-left: 9rem !important;


} */
.appContentRight {
  height: 46rem;
  margin-bottom: -10rem;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;


}
.kisiMobileContainer {
  margin-right: -10rem;
}
.appHpImgContainer {
  /* width: 20%; */
  height: 100%;
  /* margin-left: -10rem; */
  /* margin-bottom: -10rem !important;
  text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center; */
    margin-bottom: -10rem;
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto !important;
}
.appHomeImgContainer{
  height: 100%;

}
.appHomeImg2{
  margin-left: 0rem !important;

}
.appImgHp {
  height: 41rem;
  margin-top: 0rem !important;
  /* width: 100%; */
  z-index: 5;
}
.ngobrolBarengContainer {

  width: 100%;

  margin-top:3rem
}

.ngobrolImg {
  max-width: 14rem;
  margin: auto;
}
.ngobrolImgContainer {
  text-align: center;
}
.ngobrolbarengWrapper {
  width: 55%;
  margin: auto;
}
.ngobrolText {
  display: flex;
  text-align: justify;
  padding: 2rem;
  vertical-align: middle;
  display: flex;
  justify-content: justify;
  align-items: center;
}
.ngobrolUpperWrapper {
  display: flex;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-bottom: 1rem;
}
.ngobrolArrowImg {
  max-height: 1.8rem;
}
.ngobrolSlideIndicatorContainer {
  vertical-align: middle;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  line-height: 4rem;
  /* padding-left: 3rem;
  padding-right: 3rem; */
  font-size: 1.2rem;
}
.ngobrolSlideIndicatorActive {
  background-color: #0060ad !important;
  border-color: #0060ad !important;
}
.ngobrolSlideIndicator {
  width: 2rem;
  border: 2px solid #a8a8a8;
  height: 0.5rem;
  cursor: pointer;
  vertical-align: middle;
}
.ngobrolControl {
  height: 4rem;

  display: flex;
  justify-content: space-between;

  /* padding-left: 3rem;
  padding-right: 3rem; */
  font-size: 1.2rem;
  margin-bottom: 2rem;
}
.ngobrolRightArrow {
  filter: invert(29%) sepia(57%) saturate(2807%) hue-rotate(186deg)
    brightness(90%) contrast(101%);
  transform: rotate(270deg);
}
.ngobrolLeftArrow {
  filter: invert(29%) sepia(57%) saturate(2807%) hue-rotate(186deg)
    brightness(90%) contrast(101%);
  transform: rotate(90deg);
}
.ngobrolArrowContainer {
  height: 2.5rem;
  width: 2.5rem;
  background-color: white;
  border-radius: 50%;
  /* padding-left: 2.5rem;
  padding-right: 2.5rem; */
  /* justify-content: space-between; */
  display: flex;
  box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.57);
  -webkit-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.57);
  -moz-box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.57);

  line-height: 2.5rem;

  margin-left: 1rem;
  margin-right: 1rem;
  text-align: center;
  cursor: pointer;
}
.swiperNgobrol {
  text-align: center;
}

.slide-image-ngobrol {
  width: 90% !important;
  margin-right: 0.75%;
  margin-left: 0.75%;
  cursor: pointer;
}
.flashNewsSliderContainer {
  margin-top: 11rem;
  margin-bottom: 4rem;
}
.slide-image {
  height: auto;
  width: 99%;
margin-top: 1rem;
  /* border-radius: 50px; */
  position: relative;
  z-index: 2;
  box-shadow: -2px 1px 0 3px hsl(0deg 7% 78% / 48%);
  -webkit-box-shadow: -2px 1px 0 3px hsl(0deg 7% 78% / 48%);
  -moz-box-shadow: -2px 1px 0 3px hsla(0,7%,78%,.48);
  cursor: pointer;
  
}
.swiper-slide {
  
}
.slideContainer {
  align-items: center;
  justify-content: center;
  width: 100%;
  margin: auto;
  position: relative;
  
}
.slideFlashWrapper{
  width: 100%;
  margin: auto;
}
.mySwiper:after {
  content: "";
  position: absolute;
  z-index: 100;
  bottom: 0;
 right: 0;
  pointer-events: none;
  background: linear-gradient(270deg, rgba(255,255,255,0) 87%, rgba(255,255,255,1) 110%);
  width: 100%;
  height: 100%;
}
.mySwiper:before{
  content: "";
  position: absolute;
  z-index: 100;
  bottom: 0;
  left: 0;
  pointer-events: none;
  background: linear-gradient(90deg, rgba(255,255,255,0) 87%, rgba(255,255,255,1) 110%);
  width: 100%;
  height: 100%;
}
.swiper-wrapper{

  vertical-align: middle !important;
  display: flex !important;

  align-items: center !important;
}
.slideIndicatorActive {
  background-color: #0060ad !important;
  border-color: #0060ad !important;
}
.slideIndicator {
  width: 3rem;
  border: 2px solid #a8a8a8;
  height: 0.5rem;
  cursor: pointer;
}
.biayaBottomSection {
  display: flex;
  height: 15%;
  padding: 1rem;
  width: 70%;
  margin: auto;
}
.biayaBottomSection .bottomText {
  align-self: flex-end;
  padding-bottom: 2rem;
}
.bottomText {
  width: 100%;
}
.biayaImgContainer {
  width: 90%;
  margin: auto;
}
.biayaImg {
  height: 100%;
  width: 100%;
}
.kisiTalkWrapper {
  padding: 3rem;
  text-align: center;
}
.iframeKoinsContainer.center-vertical.iframeHome{
  width: 73%;
}
@media only screen and (max-width: 1600px) {
  .biayaPersen{
    font-size: 7rem;
  }
  .iframeKoinsContainer.center-vertical.iframeHome{
    width: 100%;
  }

}
@media only screen and (max-width: 1500px) {
  .kenapaImageWrapper{
    /* width: 60%; */
  }
  .biayaTransaksiSpacerCenter{
height: 16rem;
  }
}
@media only screen and (max-width: 1400px) {
  .iframe-main-playlist{
    height: 500px !important;
  }
  .ytHome iframe{}
  /* .kenapaWrapper {
    height: 40rem;
    grid-template-columns: 50% 50%;
  } */
  .thirdSectionContainer {
    height: 43rem;
    background-size: contain;
  }
  .landingContainer{
    background-position:right 23% top 0px;
  }
  .kenapaSmallText {
    font-size: 1.4rem;
  }
  .newsImageWrapper {
    height: 11rem;
  }
  .swiperContainerNgobrol {
    width: 95%;
  }
  /* .ngobrolbarengWrapper {
    width: 100%;
  } */
  .appContentRight {
    /* height: 32rem; */
    text-align: center;
    vertical-align: middle;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .biayaPersen{
    font-size: 5rem
  }
  .slideContainer{
    /* width: 75%; */
  }
  .kenapaImageWrapper{
    /* width: 75%; */
  }
  .slideIndicator{
    width: 2rem;;
  }
  .biayaTransaksiWrapper{
    width: 55%;
  }
  .ngobrolbarengWrapper{
    width: 75% ;
    margin-top: 3rem;
  }
  .appHome{
    width: 75% ;
  }
}
@media only screen and (max-width: 1300px) {
  .landingContent {
    width: 70%;
  }
  .popupImg{
    width: 40vw;
   
  }
  .landingBigText {
    font-size: 2.5rem;
  }
  .ngobrolImg{
width: 12rem;
  }
  .appContentLeft {
    width: 70vw !important;
  }

  .appContentRight {
    /* height: 20rem; */
  }
  .appImgHp {
    height: 32rem;
  }
  .appHome{
    height: 30rem;
    width: 70%;
  }
  .appLeftLowerWrapper{
    padding:3rem
  }
  .appBlueBackground{
    margin-top: 0rem !important;;
  }

  .flashNewsSliderContainer{
    margin-top: 2rem;
  }
  .ngobrolBarengContainer{
    margin-top: 0rem;
  }
}
@media only screen and (max-width: 1200px) {
  .biayaPersen{
    font-size: 4rem;
  }
  .biayaTransaksiSpacerCenter{
    height: 12rem;
  }
}
@media only screen and (max-width: 1000px) {
  .landingButton{
    font-size: 1rem;
  }
  .biayaTransaksiSpacerCenter{
    height: auto;
  }
  .biayaPersen{
    font-size: 4rem;
  }
  .kisiTalkWrapper{
    padding: 1rem 1rem 0.5rem 1rem;
  }
  .biayaTransaksiContainer{
    margin-bottom: 3rem;
  }
  .swiperContainer{
    width: 100%;
  }
  .ngobrolText{
    padding: 0rem;
  }
.ngobrolUpperWrapper{
  display: block;
}
  .container3 {
    display: block;
  }
  .kenapaImageWrapper {
    /* width: 100%; */
  }
  .promoContainer {
    display: block;
  }

  .promoImg {
    margin-top: 1rem;
  }
  .landingContainer {
    height: 40rem;
  }
  .faqWrapper {
    width: 100%;
  }
  .faqContainer {
    width: 95%;
  }
  /* .appImgHp {
    height: 50%;
  } */
  .flashNewsSliderContainer{
    margin-bottom:2rem
  }
}
@media only screen and (max-width: 768px) {
  .landingB2{
    margin-top: 1rem !important;
  }
  .iframe-main-playlist{
    height: 300px !important;
    margin: auto;
    width: 100% !;
  }
  .appTextContainer{
    padding:0rem 0.4rem 0rem ;

  }
  .popupImg{
    width: 80vw;
   
  }
  .videoPlayer{
    padding-top: 0.5rem !important;
  }
  .appCaption{
    /* font-size: 0.89rem !important; */
    margin-top: 0.75rem;
  }
  .appTextDiv{
    padding-top: 0rem !important;
  }
  .appSubtitleDiv{
    padding-top: 2.4rem !important;
  }
  .appSubtitle{
    font-size: 0.89rem !important;
  }
  .ngobrolText{
    text-align: center;
    font-size: 0.89rem;
  }
  .appTextWrapper2{
margin-top: 1rem;
  }
  .app2nd{
display: flex !important;
    justify-content: flex-end;
  }
  .appContainer2{
    margin-top: 0rem;
  }
  .kenapaImageWrapper {
    grid-template-columns: 50% 50%;
    grid-row-gap: 4rem;
  }
 .appTextWrapper{
  width: 95%;
 }
  .appHome{
    width: 100%;
    height: auto;
    /* display: grid ;
    grid-template-columns: 70% 30%; */
    margin-top: 0.5rem;
    display: flex;
    justify-content: flex-end;
 flex-direction: column-reverse;
  }
  .faqText {
    margin-bottom: 2rem;
  }
  /* .biayaTransaksiSpacerCenter{
    height:15rem;
  } */
  /* .biayaTransaksiSpacerCenter{
    padding-bottom: 2rem;
  } */
  .slideIndicator {
    width: 1rem;
    height: 0.2rem;
    background-color: transparent ;
    border: 1px solid #a8a8a8 !important;
  }
  .ngobrolSlideIndicator{
    width: 1rem;
    height: 0.2rem;
    background-color: transparent ;
    border: 1px solid #a8a8a8 !important;

  }
  .kisiTalkImg{
    width: 50%;
  }
  .kisiTalkContainer{
    margin-top: 2rem;
  }
  .appBlueBackground{
    margin-bottom: 4rem !important;;
  }
  .slide-image-ngobrol{
    width: 85% !important;
  }
  .appContent{
    height: 42rem;
    margin-top: -42rem;
  }
  .appButton{
    margin-top: 1rem;
  }
  .appContentLeft{
    width: 90vw !important;
  }
  .swiperContainer{
    margin-top:1rem;
  }
  .flashNewsSliderContainer{
    margin-top:5rem
  }
  .draggableSlideImg{
    width: 10rem !important;
    height: 60%;
  }

  .faqWrapper{
    height: auto;
    max-height: 65px;
  }
  .faqWrapper:hover {
    height: auto;
    max-height: 375px;
    z-index: 2;
    border-color: #0071bf;
  }
  .kenapaSmallText {
    font-size: 0.9rem;
    margin-top: 0.35rem;
  }
  .faq2{
    display: block !important;
  }
  .kenapaImg{
    width: 90%;
  }
  .kenapaWrapper {
    margin-bottom: 2rem;
  }
  .kenapaLeftContainer {
    display: none;
  }
  .faqTitle {

    height:65px;
    display: grid;
    grid-template-columns: 90% 10%;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .biayaTransaksiWrapper {
    width: 95%;
    border-width: 4px;
  }
  .ngobrolUpperWrapper {
    display: block;
  }
  .appLeftLower {
  }
  .appContentLeft .appContentRight {
    display: inline-block;
  }
  /* .appContentWrapper{

  display:flex;
  flex-flow: column;
  }
  #koinsWrapper{
    order:2
  }
  #koinsImg{
    order: 1;
  } */
  .appHpImgContainer {
    /* height: 40%; */
  }
  .biayaPersen {
    font-size: 3.3rem;
    margin-bottom: 0.5rem;
    margin-top:-10px
  }
  .biayaTitle {
    font-size: 2rem;
    margin-top: 0.1rem !important;
  }
  .biayaBottomSection {
    width: 100%;
  }
  .sliderSmall {
    font-size: 0.78rem;

  }
  .biayaTransaksiWrapper {
    height: auto;
  }
  .biayaBottomSection {
    height: auto;
    max-width: 80%;
    padding: 0.5rem;
  }
  .biayaTransaksiContainer{
    margin-top:2.5rem
  }
  .biayaTitleContainer {
    height: auto;
  }
  .bottomText{
    font-size: 0.8rem;
  }
  .biayaTransaksiGridContainer {
    /* display: block; */
    height: auto;
    margin-top: 1rem;
  }
  .slideContainer {
    width: 99.9%;
  }
  .container3 {
    display: block;
  }
  .promoContainer {
    display: block;
  }
  .articleButtonContainer {
    margin-top: 2rem;
  }
  .promoImg {
    /* margin-top: 1rem; */
  }
  .biayaSubtitle{}
  .thirdSectionContainer {
    margin-top: 1rem;
    height: 20vh;
    margin-bottom: 1rem;
  }
  .mobileLogo {
    width: 30%;
  }
  .mobileAppSpacer {
    margin-top: 1rem;
  }
  .fourthSection {
    text-align: center;
  }
  .kenapaImageContainer:hover  .reksadanaDescriptionContainer{
    visibility: hidden;
    opacity: 1;
  }
  .landingContainer {
    width: 100%;
    height: 33rem;
    margin: auto;
    /* background-size:50rem; */
    background-repeat: no-repeat !important;
    background-position:right 23% top 0px;
    vertical-align: bottom;
    z-index: 2;
  }

  .landingContent {
    width: 70%;
    margin-left: 1rem;
    padding-top: 2%;

  }

  .landingBigText {
    font-size: 1.75rem;
    /* white-space: pre-line !important; */
  }
  .sliderArrow{
    height: 2.25rem !important;
  }
  .mySwiper{
    width: 100%;
  }
  .arrowLeft{
    margin-right: -0.75rem !important;
  }
  .arrowRight{
    margin-left: -0.75rem !important;
  }
  .landingButton {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
    margin-top: 0rem;
    padding-top: 0.1rem!important;
    padding-bottom: 0rem!important;

    font-size: 1rem;
  }
  .mobileAppTitle {
    font-size: 1rem;
  }
  .mobileAppContainer {
    display: block;
    padding: 1rem;
    margin-top: 1rem;
  }
  .kenapaWrapper {
    width: 100%;
    display: block;
    height: auto;
    margin-top: 1.5rem;
  }
  .mobileSpacer .kenapaLeftContainer {
    display: none;
    height: 10rem;
  }
  .kenapaBigText {
    font-size: 1.6rem;
    margin-top: 2rem;
        margin-bottom: 1rem;
  }

  .blue-btn {
    width: auto;
    height: auto;
  }
  .promoSection {
    width: 90%;
    padding: 1rem;
  }
  .newsSection {
    margin-top: 3rem !important;
    margin-bottom: 2rem;
  }
  /* .appHeaderText {
    margin: auto;
  } */
  .faqIndicator{
  /* display: none; */
  width: 20px;
  height: 20px;
  margin: auto;
  }

  .faqAnswer{
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.9rem;
  }
  .faqQuestion{
    padding-right: 1rem;
    font-size: 0.9rem;
  }
  .landingSmallText{
    font-size: 0.83rem;
    white-space: pre-line !important;
    margin-top: 0.4rem !important;
  }
  .landingButtonContainer{
    margin-top: 1rem !important;
  }
  .slideContainer{
    /* width: 95%; */
  }
  .ngobrolbarengWrapper{
    width: 95%;
    margin-top: 5rem;
  }
  .kenapaImageWrapper{
    width: 100%;
    margin-top: 2rem;
  }
  .appSliderInfoContainer{
    height: 9rem !important;
    padding-left: 0.7rem !important;
    padding-right: 0.7rem !important;
    padding-top: 1rem !important;
  }
  .kenapaLeft{
    margin-left: auto;
  }
  .kenapaRight{
    margin-right: auto;
  }
  .kenapaSmallWrapper{
    width:70%
  }
  .kenapaBottom{
    margin-top:1.3rem
  }
  .mySwiper:before{
    content:none;

  }
  .mySwiper::after{
    content:none;

  }
  .slide-image{
    box-shadow: none;
  }
  .swiperPaginationDiv{
    margin-top: 1rem !important;
  }
  .swiperPaginationDiv2{
    margin-top: -0.5rem !important;
  }
  .biayaBottomSection .bottomText {

    padding-bottom: 0.75rem}
    .appHomeImgContainer{
      margin-top: 1rem;
    }
    .selengkapnyaButton{
      margin-top: -20px;
    }
    .selengkapnyaButtonDiv{
      margin-bottom: 0.5rem !important;
    }
    .app2nd{
      flex-direction: column!important;
    }
    .ngobrolControl{
      margin-bottom: 0rem;
    }
    .appSpacerLeft{
      width: 13%;
      background-color: white;
      z-index: 99;
    text-align: right;
    
    }
    .appSpacerRight{
      width: 13%;
      background-color: white;
      z-index: 99;
    }
    .swiperLangkah{
      width: 80%;
    
    }
    .langkahText{
      margin-bottom: 0.5rem !important;
    }
    .testiSpacer{
      display: none !important;
      width: 0px !important;
    }
    .swiperTesti{
      width: 95% !important;
    }
    .faqDiv{
      margin-top: 2rem;
    }
    .faqAnswerContainer {
      margin-top:0rem;
    }
    .faqWithButton{
      width: 100% !important;
    }
    .pelajariFaqButton{
      margin: auto;
      margin-top: 1.2rem;
      margin-bottom:0rem;
      padding-top:0.3rem !important;
      padding-bottom:0.3rem !important;
    }
    .pelajariFaq{
      margin-right: 0rem;
    }
}
