#lokasiIndonesiaContainer {
  text-align: center;
  margin-top: 4rem;
}

#lokasiKamiDi {
  font-size: 1.6rem;
}



.cityTableContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  margin-top: 5rem;
}
.headquarterTitle {
  border: none;
  width: 40rem;
  height: 4rem;
  margin: auto;
  vertical-align: center;
  background-color: #0060ad;
  color: white;
  font-size: 1.7rem;
}
.branchTitle {
  border: none;
  width: 23rem;
  height: 4rem;
  margin: auto;
  vertical-align: center;
  background-color: #0060ad;
  color: white;
  font-size: 1.7rem;
  z-index: 3;
}
.headquarterContent {
  border: 3px solid #0060ad;
  height: 11em;
  width: 34rem;
  margin: auto;
  margin-top: -1rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.branchContent {
  border: 3px solid;
  border-color: transparent;
  color: transparent;
  height: 0px;
  width: 20rem;
  margin: auto;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 0;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}

.show {
  height: 11em;
  color: black;
}
.branchCity:hover + .branchContent,
.branchCity:hover #jakartaAddress {
  height: 8em;
  color: #6e6f72;
  line-height: 1.5;
  border-color: #0060ad;
  border-top: none;
}
#jakarta:hover + #jakartaAddress {
  color: black;
}
/* #jakarta:hover + #branchAddress {
   color:  #6e6f72 !important
   } */
.roundedBorder {
  border-radius: 0rem 0rem 1rem 1rem;
}

.headquarterAddress {
  width: 70%;
}

.branchAddress {
  width: 70%;
}
#addressBanjarmasin{
  width:90%;
  font-size: 0.8rem;
}
#jakartaAddress {
  /* margin-top: 5rem; */
  /* z-index: 0; */
  /* color:transparent */
  /* display:    none; */
  transition: none !important;
}

.branchSpacer {
  height: 15rem;
}
.branchContainerLeft {
  display: flex;
  margin-right: 7%;
}
.branchContainerRight {
  display: flex;
  margin-left: 7%;
}
.branchLeft {
  display: flex-end !important;
}
@media only screen and (max-width: 768px) {
  .headquarterAddress {
    width: 90%;
  }

  #lokasiKamiDi {
    font-size: 0.9rem;
  }
  #indonesia {
    font-size: 1rem;
  }
  #lokasiIndonesiaContainer {
    margin-top: 1rem;
  }
  .headquarterContent {
    width: 18rem;
  }
  .headquarterTitle {
    width: 21rem;
    height: 2.5rem;
    font-size: 1.3rem;
  }
  .branchTitle {
    width: 17rem;
    height: 2.5rem;
    font-size: 1rem;
  }

  .branchSpacer {
    height: 15rem;
  }
  .branchContent {
    height: 10rem !important;
    width: 15rem;
    border-color: #0060ad;
    color: black;
    line-height: 1.5;
    color: #6e6f72;
    border-top:none
  }
  .branchAddress {
    width: 95%;
  }
  .branchContainerLeft {
    display: block;
    margin-right: 0px;
  }
  .branchContainerRight {
    display: block;
    margin-left: 0px;
  }
  .cityTableContainer {
    display: block;
  }
}

#main-image {
  height: 0;
  overflow: hidden;
  background: red;
  -moz-animation: slide 1s ease 3.5s forwards;
  -webkit-animation: slide 1s ease 3.5s forwards;
  -o-animation: slide 1s ease 3.5s forwards;
  -ms-animation: slide 1s ease 3.5s forwards;
  animation: slide 1s ease 3.5s forwards;
}
