.CIMBPageWrapper {
  margin-bottom: 7rem;
}

.promoPageContainer {
  width: 100%;
}

.CIMBImg {
  width: 100%;
}

.langkahEtf {
  margin-top: 0rem !important;
}
.promoPageWrapper {
  /* line-height: var(--unnamed-line-spacing-30); */
  letter-spacing: var(--unnamed-character-spacing-0-12);
  color: var(--unnamed-color-787878);
  width: 62%;
  margin: auto;
  margin-top: 1rem;
}
.CIMBPdfButton {
  border: 2px solid #707070;
  border-radius: 20px;
  opacity: 1;
  padding-top: 0.2rem;
  padding-bottom: 0.2rem;
  background-color: white;
  transition: 0.2s;
}
.noList{
    padding-left: 0rem !important;
}
.CIMBButtonWrapper {
}
.etfPromoBanner{
    margin-bottom: 2rem !important;
}
.etfContainer.productBanner.etfPromoBanner{
    width: 90% !important;
}
.langkahPendaftaranWrapper.langkahEtf {
    width: 100% !important;
}
.CIMBPdfButton:hover {
  color: white;
  background-color: #0071bf;
  border-color: #0071bf;
}
.marginPromoText{
    position: relative;
    z-index: 88;
  margin-left: 0 !important;
  margin-bottom: 1% !important;
}

@media only screen and (max-width: 1500px) {
  .promoPageWrapper {
    width: 70%;
  }
  .CIMBImgContainer {
    width: 70%;
  }
}
@media only screen and (max-width: 1300px) {
  .promoPageWrapper {
    width: 90%;
    margin-top: 2rem !important;
  }
  .marginPromoBanner{
    width:70% !important ;
}
  .CIMBImgContainer {
    width: 80%;
  }

  .etfContainer.productBanner.etfPromoBanner{
    width: 85% !important;
}
}
@media only screen and (max-width: 1000px) {
    .etfContainer.productBanner.etfPromoBanner{
        width: 100% !important;
    }  
}
@media only screen and (max-width: 768px) {
  .promoPageWrapper {
    width: 95%;
  }
  .etfPromoDownload{
    margin-top: 1rem !important;
  }
  .CIMBImgContainer {
    width: 95%;
    margin-top: 3rem;
  }
  .CIMBPageContainer .langkahPendaftaranWrapper {
    margin-top: 0rem !important;
  }
  .promoPageWrapper{
    margin-top: 0rem !important;
  }
  .etfContent {
    width: 100% !important;
  }
  .etfContent .downloadImg {
    width: 80% !important;
    height: 40px;
  }
  .my-5.f25r.nowrap.blue-text-1.monsterratExtraBold.text-center {
    font-size: 1.1rem;
    white-space: normal;
    margin-bottom: 0rem !important;
    margin-top: 1rem !important;
  }
  .blue-btn.f2r.rounded-pill.monsterratBold.downloadButton {
    font-size: 1.1rem !important;
    height: auto !important;
  }
  .etfPromoTextContainer{
    margin-top: 2rem !important;
  }
  .etfPromoDownloadButtonContainer{
    margin-right: 0rem !important;
    margin-left: 0rem !important;
  }
  .etfImgPromo{
    height: 62% !important;
    bottom:-8rem!important
  }
  .promoMenuPageWrapper{
    display: block;
    width: 90%;
  }
  .promoMenuWrapper{
    width: 100%;
    margin-top: 3rem !important;
  }
  .promoMenuPageContainer{
    margin-bottom: 8rem;
  }
  .promoMarginContainer{
    margin-top: 4rem !important;
  }
  .marginPromoMarketplace{
    margin-top: 0rem !important;
  }
  .marginPromoImg{
    height: 66% !important;
    top:16rem !important
  }
  .f25r.monsterratExtraBold.my-5.text-center.blue-text-1.mb-5.pb-5{
    margin-top: -1rem !important;
    margin-bottom: -2rem !important;
  }
}
