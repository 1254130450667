.disclaimerPageContainer{
    width: 55%;
    margin: auto;
    
}
.disclaimerContainer{

    line-height: 1.8;
    letter-spacing: var(--unnamed-character-spacing-0-12);
color: var(--unnamed-color-787878);

font: normal normal medium 20px/30px Montserrat;
letter-spacing: 0.12px;
}
.kebijakanContainer{
    line-height: 1.8;
    letter-spacing: var(--unnamed-character-spacing-0-12);
color: var(--unnamed-color-787878);

font: normal normal medium 20px/30px Montserrat;
letter-spacing: 0.12px;

}
.informasiContainer{
    line-height: 1.8;
    letter-spacing: var(--unnamed-character-spacing-0-12);
color: var(--unnamed-color-787878);

font: normal normal medium 20px/30px Montserrat;
letter-spacing: 0.12px;
}
.informasiPoin{
    border:3px solid #707070;
    margin-top:5rem;
    border-radius: 28px;
}