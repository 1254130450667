.windowsStore {
  margin-left: 1rem;
}

.koinsContainer {
  display: grid;
  grid-template-columns: 50% 50%;
  margin: auto;
  margin-top: -3rem;
  width: 60%;
  /* padding-left: 5rem; */
}
.container {
  display: flex;
}
.jenisAkunKoins {
  margin-top: 4rem !important;
}
.temukanText {
  width: 70%;
  white-space: nowrap;
}
.panel{
  width: 100% !important;
}
.resetResult{
  font-family: "Montserrat","Mukta","Tahoma",sans-serif;
  font-size: 13px !important;
  color: #000000 !important;
}
/* .row.mt-5.mx-auto{
  width: 70% !important;
} */
.videoPlayerKoins {
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.d-hidden{
  display: hidden !important;
}
.langkahPendaftaranTitle {
  /* max-width: 60%; */
}
.panel-body {
  margin-top: 1px;
  margin-bottom: 1px;
}
.panel-body {
  padding: 15px;
}
.panel-default {
  border-color: #ddd;
}
.reset-page{
  background-color: #efefef;
}
.panel-remark {
  text-align: left !important;
  font-size: 10px;
  font-weight: normal !important;
}
.btn-reset {
  background-color: #6c99d7;
  color: #fff;
  border: 1px solid #6c99d7;
  border-radius: 3px;
  transition: 0.5s !important;
}
.btn-reset, .btn-secondary, .btn-footer {
  font-size: 13px;
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  padding: 0;
  line-height: 49px;
  width: 190px;
  height: 51px;
  text-align: center;
  border-radius: 3px;
  font-family: 'Montserrat', sans-serif;
}
.btn-reset:disabled{
  background-color: grey !important;
  /* transition: none; */
  border: none !important;
}
.btn-reset:hover{
  color: white !important;
}
.reset-hyper{
  color: rgb(90, 40, 11);
  outline: none;
  text-decoration: none;
  font-size: 12px;
  transition: 0.3s ease;
}
.reset-hyper:hover{
  color: #f3b200;
    outline: none;
    text-decoration: none;
}
.panel {
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 4px;
  -webkit-box-shadow: 0 1px 1px rgba(0,0,0,.05);
  box-shadow: 0 1px 1px rgba(0,0,0,.05);
}
.reset-form {
  background-color: transparent;
  -moz-border-radius: 0;
  -webkit-border-radius: 0;
  border-radius: 0;
  border-top: none;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #e5e5e5;
  font-size: 14px;
  color: #000000;
  line-height: 50px;

  margin-bottom: 50px;
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  padding-left: 0;
  border-radius: 3px !important;
  display: block;
  width: 100%;
  height: 50px;
  padding: 6px 12px;
  font-size: 14px;

  color: #555;
  background-color: #fff;
  background-image: none;
  margin-bottom: 10px;
  border-radius: 4px;
  /* -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075); */
  -webkit-transition: border-color ease-in-out 0.15s,
    -webkit-box-shadow ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.reset-form:focus {
  border-color: none;
  box-shadow: none;
  outline: 0 none;
}
.koinsImgContainer {
  height: 35rem;
  margin: auto;
  text-align: center;
  /* width: 50%; */
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 120%;
}
.koinsTextContainer {
  /* margin-left: 10rem; */

  margin-right: 0px;
}
.koinsStore {
  text-align: center;
  margin: auto;
}
.koinsDownload {
  height: 2.1rem;
  text-align: center;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 2.2rem;
  padding-right: 2.2rem;
}
.koinsSmallText {
  line-height: 1.5;
  /* letter-spacing: 1px; */
  margin-top: 1rem;
  /* font-size: 1.2rem; */
}
.ytOtherContainer {
  /* height: 100%;
  overflow: auto */
  /* background: rgba(0,0,0,.8); */
  /* opacity: 20%; */
}
#youtube-playlist-container {
  display: block;
  height: 500px;
  overflow: auto;
  padding-left: 1rem;
  padding-right: 1rem;
  
}
#youtube-playlist-container:first-child {
  padding-top: 0 !important;
}
.koinsMarketplaceContainer {
  margin-top: 2rem !important;
  text-align: center;
  justify-content: flex-end;
  grid-gap: 1rem;
  margin: auto;
  display: flex;
}
.featureTextKoins {
  margin-top: 2rem;
  font-size: 1.3rem;

  padding-bottom: 1.5rem;
}
.youtube-video-image {
  border-radius: 3px;
  /* width: 35% !important; */
  /* padding-right: 1rem; */
}
.iframeKoinsContainer {
  width: 70%;
  text-align: center;
  /* height: 560px;; */
  margin: auto;
  margin-top: 3rem;
  display: flex;
  /* border: 1px solid black; */
  padding: 2rem;
  /* box-shadow: 0 8px 24px rgb(0 0 0 / 15%);
    border: 2px solid black; */
  /* filter: drop-shadow(0px 0px 3px #000); */
}
figure {
  margin-block-end: 0em !important;
  /* display: flex !important; */
  padding-top: 1rem;
  filter: drop-shadow(0px 1px 2px #000);
}
figcaption {
  font-weight: 500;
  font-size: 0.9rem;
  margin-top: 0.4rem;
  padding-bottom: 1.4rem !important;
  display: none;
}
.iframe-main-playlist{
  height: 500px;
}
.iframe-koins {
  filter: drop-shadow(0px 1px 2px #000);
}
.featureTextKooins {
  font-size: 1.2rem;
}
.jenisAkunContainer {
  line-height: 2;
  width: 60%;
  margin: auto;
  margin-top: 7rem;
}

.jenisAkunText {
  width: 80%;
  margin: auto;
  margin-top: 2rem;
}

.jenisAkunCardContainer {
  text-align: center;
  margin: auto;
  display: flex;
  width: 100%;
  margin-top: 4rem;
  margin-bottom: 7rem;
}
.jenisAkunCardWrapper {
  margin: auto;
  display: flex;
  width: 90%;
}
.koinsPageImg {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: 3rem;
}

.jenisAkunCard {
  width: 30%;
  padding: 3rem 2rem 3rem 2rem;
  border: 5px solid #0071bf;
  margin-right: 1.3rem;
  margin-left: 1.3rem;
  border-radius: 6px;
}
.koinsBottomButton {
  margin-bottom: 8rem !important;
}
.pelajariButton {
  /* padding: 0.75rem; */
  background-color: #0071bf;
  /* width: 70%; */
  padding-right: 2rem;
  padding-left: 2rem;
  border: 1px solid #0071bf;
  font-size: 0.8rem;
  color: white;
}
.pelajariButton:hover {
  box-shadow: 0 1px 10px rgb(0, 113, 191);
}
.koinsUpperImg {
  display: none;
  max-height: 100%;
  max-width: 100%;
  margin: auto;
}
@media only screen and (max-width: 1500px) {
  /* .downloadButton {
    width: 70%;
  } */
  .koinsContainer {
    grid-template-columns: 50% 50%;
  }
  .koinsMarketplaceContainer {
    width: 90%;
  }
  .koinsTextContainer {
    margin-left: 2rem;
  }
  .rocketImgContainer {
  }
  .iframeKoinsContainer {
    width: 90%;
  }
  .temukanText {
    width: 100%;
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1050px) {
.ytOtherContainer{
    width: 100% !important;
  }
  .iframeKoinsContainer{
    display: block !important;
  }
  #youtube-playlist-container{
    display: flex;
    height: auto;
    padding-bottom: 1.7rem;
  }
  .youtube-video-figure{
    width: 2090px !important;
  }
  .youtube-video-image{
    width: 150px;
  }
}

@media only screen and (max-width: 1000px) {
  .koinsContainer {
    display: block;
  }
  .iframeKoinsContainer {
    display: block;
  }
  .koinsUpperImg {
    display: block;
    margin-top: 2rem;
  }
  .koinsPageImg {
    display: none;
  }
  .jenisAkunText {
    width: 85%;
  }
  .pelajariButton {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .jenisAkunCard {
    padding: auto;
  }
  .jenisAkunCardWrapper {
    /* width: 100vw !important; */
    margin: auto;
  }
}
@media only screen and (max-width: 768px) {
  .resetImgDiv{
    float: none !important;
  }
  .koinsContainer {
    width: auto;
    margin-top: 1rem;
  }
  .iframeKoinsContainer {
    width: 95%;
    padding: 0rem;
  }
  .koinsImgContainer {
    display: none;
  }
  .container {
    display: block;
  }
  .langkahPendaftaranTitle {
    /* width: 80%; */
    font-size: 1.4rem;
    margin-bottom: 2rem !important;
    text-align: center;
    margin: auto;
    white-space: normal !important;
    width: 95%;
    padding-left: 0rem;
    padding-right: 0rem;
    margin-top: 1.5rem !important;
  }
  .langkahPendaftaranImg {
    /* width: 30%; */
  }
  .koinsTextContainer {
    width: auto;
    margin-left: 0rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .jenisAkunCardWrapper {
    display: block;
    width: 100%;
    margin: auto;
  }
  .jenisAkunCard {
    padding: 0rem 0rem 2rem 0rem;
    margin: auto;
    margin-bottom: 1rem;
    width: 80%;
  }
  .videoText {
    width: 80%;
    font-size: 1rem !important;
    margin: auto;
  }
  .koinsMarketplaceContainer {
    /* display: block !important; */
    text-align: center;
    margin: auto;
    width: 100%;
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .flex .koinsMarketplaceContainer {
    margin-top: 1rem !important;
  }
  .featureTextKoins {
    margin-top: 0rem;
    font-size: 1rem;
    margin-left: 0rem;
    padding-bottom: 0.8rem;
    text-align: center;
  }
  .koinsTextContainer {
    padding-top: 2rem;

    padding-top: 0px;
  }

  .w50.flex {
    display: block;
  }
  .jenisAkunText {
    width: 85%;
    margin-top: 1rem;
    line-height: 2;
  }
  .koinsStore {
    width: 100% !important;
    margin: auto !important;
    margin-top: 1rem !important;

    text-align: center !important;
  }
  .jenisAkunCardContainer {
    margin-bottom: 3rem;
    margin-top: 2rem;
  }
  .jenisAkunContainer {
    margin-top: 2.5rem;
  }
  .koinsBottomButton {
    font-size: 0.7rem !important;
    margin-bottom: 2rem !important;
  }
}
