.downloadSekarang {
  background-color: #0060ad;
  margin-top: 5vh;
  vertical-align: middle;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1.5rem;
}

.downloadImg {
  height: 3rem;
  cursor: pointer;
}

.mulaiButton {
  border: none;
  height: 2.5rem;
  padding-left: 1.5em;
  padding-right: 1.5em;
  font-family: StemRegular;
  border-radius: 5px;
  font-size: 20px;
  color: #0060ad;
  width: 10rem;
}
.center {
  justify-content: center;
  align-items: center;
}
.downloadBannerText {
  font-size: 1.7em;
  text-align: center;
  color: white;

  height: auto;
  align-self: center;
  vertical-align: middle;
}

.innerBanner {
  margin: auto;
  display: grid;
  grid-template-columns: 50% 50%;

  width: 80%;
}
.innerRightBanner {
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1170px) {
  .downloadBannerText {
    display: none !important;
  }
  .innerRightBanner {
    margin: auto;
  }
  .innerBanner{
    grid-template-columns: none;
  }
}
@media only screen and (max-width: 768px) {
  .downloadBannerText {
    display: none !important;
  }
  .bannerSpacer {
    display: none;
  }

  .downloadImg {
  }
  .innerBanner {
    display: block;
    width: 100%;
  }
  .innerRightBanner {
    display: block;
    text-align: center;
  }
  .downloadSekarang {
    padding: 2rem;
    height: auto;
  }
}
