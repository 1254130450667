.wa-contact {
    position: fixed;
    z-index: 999;
    bottom: 15px;
    right: 15px;
    text-align: right;
    width: 200px;
    height: 50px;
    border-radius: 50px;
    overflow: hidden;
    background: #1bd741aa;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    transition: 0.4s;
}
.tele-contact{
    background: #429bf5aa;
}
.kontakSalesButton{
    position: relative !important;
    height: 30px;
    border-radius: 50px;
    overflow: hidden;
    background: #1bd741aa;
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
    transition: 0.4s;
}
.wa-contact-icon {
    display: inline-block;
    margin-left: auto;
    width: 50px;
    height: 50px;
    border-radius: 50px;
    background: url('../../assets/whatsapp.png') #1bd741 center center no-repeat;
    background-size: 90%;
}
.tele-icon{
    background: url('../../assets/telegram.svg') #429bf5 center center no-repeat;
    background-size: 100%;
}
.wa-contact-txt {
    display: inline-block;
    width: 90px;
    height: 30px;
    color: #000;
    line-height: 1.2;
    letter-spacing: .2px;
    padding-left: 19px;
    background: 0 0;
    font-size: .7rem;
    text-align: center;
    text-decoration: none !important;
}
.d-block {
    display: block!important;
}
.wa-contact a {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    text-decoration: none !important;
}

a{

}