

.kisiMobileRegistration {
  display: flex;
  grid-template-columns: 50% 45%;
  margin: auto;
  width: 60%;
  transition: 0.4s;
}
.kisiAdj{
  margin-top:-1rem
}
.kisiMobileBigText {
  font-size: 3rem;
  margin-bottom: 1rem;
  white-space: nowrap;

}
.rocketUpperImg{
  display: none;
}
.secondSectionWrapper{
  margin-top: 4rem;
}
.kisiMobileTextContainer {
  /* width: 40rem; */
  width: 50%;
  /* margin-top: 5rem; */
  /* padding-left: 5rem; */
  margin-right: 0px;
}
.registrationNoteWrapper{
  width: 60%;
  border:3px solid 
  #0071bf;
  margin:auto;
  margin-top:5rem
}
.rocketImgContainer {
  height: auto;
  margin: auto;
  text-align: center;
  width: 50%;
  vertical-align: middle;
  display: flex;
  justify-content: center;
  align-items: center;
}
.rocketImg {
  max-height: 100%;
  max-width: 100%;
  margin: auto;
  margin-top: -3rem !important;
}
.downloadButton {
  /* width: 100%; */
  /* height: 3.5rem; */

  border-radius: 1.25rem;
  font-size: 1.4rem;
  transition: 0.3s;
}
.downloadButton:hover {
  border: 3px solid #0071bf ;
  color: #0071bf ;
  background-color: transparent;
  box-shadow: 0 1px 10px rgb(0 113 191);
}
.deleteAccForm{
  display: grid;
  grid-template-columns: 20% 80%;
  text-align: left;
  margin-bottom: 2rem !important;
}

.downloadButtonGroup {
  margin-top: 3rem;
}
.mobileMarketplace {
  width: 8rem;
  cursor: pointer;
}
.downloadButtonContainer {

  display: inline-block;
}

.mobileMarketplaceContainer {
  margin: auto;
  margin-top: 1rem;
  text-align: center;
  justify-content: space-evenly;
  /* justify-content: space-around;
  width: 80%; */
}
/* .playStore {
  margin-left: 0.5rem;
}
.appStore {
  margin-right: 0.5rem;
} */

.disclaimerText {
  font-size: 0.7rem;
  margin-top: 1rem;
  width: 100%;
}
.disclaimerText2 {
  width: 60%;
}
.container {
}

.kisiMobileSecondSection {
  width: 55%;
  margin: auto;
  height: auto;
  /* box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.68) inset;
  -webkit-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.68) inset;
  -moz-box-shadow: 0px 0px 7px 1px rgba(0,0,0,0.68) inset; */
  border-radius: 2rem;
  display: flex;
  /* grid-template-columns: 25% 25% 25% 25%; */
  margin-top: 4rem;
}
.videoKisiMobile{
  filter: drop-shadow(0px 1px 2px #000);
  width: 60%;
  border-radius: 8px;
}
.kisiMobileFeature {
  width: 100%;
  max-height: 100%;
  text-align: center;
}
.mobileFeatureImg {
  width: 75%;

  margin: auto;
}

.featureText {
  margin-top: 1rem;
  font-size: 1.2rem;

  padding-bottom: 1.5rem;
}

.langkahPendaftaranTitle {
  text-align: center;
  font-size: 2rem;
  margin: auto;
  margin-top: 2rem;
  margin-bottom: 4rem !important;
  padding-left:3rem;
  padding-right:3rem;
  max-width: 90%;
}
.langkahPendaftaran {
  width: 100%;
  max-height: 100%;
  text-align: center;
  /* margin: auto; */
}
.langkahPendaftaranImg {
  max-height: 100%;
  margin: auto;
  vertical-align: middle;
  max-width: 90%;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
.stepText{
  height: 1rem;
}
.w60 {
  width: 60%;
}
.langkahPendaftaranContainer {
  display: grid;
  grid-template-columns: 14% 15% 14% 15% 14% 15% 14%;
  text-align: center;
  width: 90%;
  margin: auto !important;
  margin: top 3rem;
  margin-bottom:5rem !important
}
.langkahPendaftaranText {
  /* margin-top: 2rem; */
  width: 150%;
  margin-left: -25%;
}
.helper {
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}
.langkahSpacer {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  color: #0071bf ;
  font-weight: 900;
  height: 11rem;
 
  font-size: 2rem;
}
.videoKisiMobileWrapper{
  margin-bottom: 6rem;
  margin-top: 5rem;
}
.mail {
  width: 82% ;
}
.videoPlayerKisiMobile{
  margin-top: 3rem;
}
.resume {
  width: 80%;
}
.langkahPendaftaranImgContainer {
  height: 11rem;
  margin: auto;
  margin-top: -1rem;
  /* display:flex; */
  align-items: center;
  justify-content: center;
}
.paddingB5 {
  padding-bottom: 1.9rem;
}
.registrationNote {
  width: 80%;
  text-align: center;
  margin: auto;
  /* font-size: 1.2rem; */
}
.tutorialTitle {
  font-size: 1.5rem;

  text-align: center;
  margin-top: 6rem;
}
.w60 {
  width: 60%;
}
.downloadKoinsKisiButton{
  color: #0071bf  !important ;
  background-color: white;
  border: 1px solid #6e6f72;
  padding:0.3rem 1.5rem 0.3rem 1.5rem;
  border-radius: 10px;
}
.langkahPendaftaranWrapper{
  width: 60%;
  margin: auto;
  margin-top: 5rem !important;
  
}
@media only screen and (max-width: 1600px) {
  .kisiMobileRegistration{
    width: 88%;
  }
  .langkahSpacer{
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 1400px) {
  .langkahPendaftaranContainer {
    width: 85%;
  }
  .kisiMobileBigText {
    font-size: 2rem;
  }
  /* .downloadButtonContainer {
    width: 90%;
  } */
  .kisiMobileSecondSection{
    /* width: 90%; */
  }
}
@media only screen and (max-width: 1300px) {
  .langkahSpacer{
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 1200px) {
  .langkahSpacer{
    font-size: 1.1rem;
  }
  .langkahPendaftaranWrapper{
    width: 90%;
  }
}

@media only screen and (max-width: 1050px) {
  .kisiMobileRegistration {
    width: 97%;
  }
  .kisiMobileTextContainer{
    padding-left: 2rem;
    width: 66%;
  }
  .langkahSpacer{
    font-size: 1rem;
  }

}

@media only screen and (max-width: 768px) {
  .deleteAccForm{
    display: block;
  }
  .temukanText{
   width: 90%;
   white-space: normal !important;
  }
  .rocketUpperImg{
    display: block;
    width: 50%;
    margin: auto;
  }
  .langkahPendaftaranImgContainer{}
  .kisiMobileRegistration {
    display: block;
    margin-bottom:2rem
  }
  .rocketImgContainer {
    display: none;
  }

  .kisiMobileBigText {
    font-size:2rem;
  }
  .langkahPendaftaranWrapper{
padding-bottom: 0.1rem !important;
  }
  .downloadButtonContainer {
    width: fit-content;
    text-align: center;
  }
  .kisiMobileTextContainer {
    padding-top: 2rem;
    padding-left: 0px;
    padding-top: 0px;
    margin-top:1rem;
    width: 90%;
    margin: auto;
  }
  .featureText {
    margin-top: 0rem;
    font-size: 0.4rem;
    margin-left: 0rem;
    padding-bottom: 0.8rem;
    text-align: center;
  }
  .kisiMobileSecondSection {
    margin-top: 5rem;
    padding-top: 0.5rem;
  }

  .langkahPendaftaranContainer {
    display: block;
    width: 90%;
  }
  .langkahSpacer {
    display: none;
    height: 3rem;
  }
  .registrationNote {
    width: 90%;
  }
  .langkahPendaftaranText {
    margin-top: 0px;
    font-size: 1rem;
    width: 80% !important;
    margin: auto;
 margin-left: auto !important;
  }
  .kisiMobileSecondSection {
    display: grid;
    grid-template-columns: 50% 50%;
    width: 95%;
    box-shadow: none;
    margin-top: 2rem;
    row-gap: 1.5rem;
  }
  .koinsStep{
    width: 6rem !important;
  }
  .videoKisiMobileWrapper{
    margin-bottom: 2rem
  }
  .videoKisiMobile{
    width: 90%;
  }
  .featureText {
    font-size: 1.2rem;
  }
  .kisiMobileBigText {
    font-size: 1.8rem;
  }
.stepText{
  font-size: 1.4rem;
  margin-top:2rem
}
  .mobileMarketplace {
    width: 5.5rem;
  }
  .langkahPendaftaranTitle {
    margin-top: 3rem !important;
    margin-bottom: 0px;
  }
  .langkahPendaftaran {
    margin-bottom: 1rem;
  }
  .resume {
    /* width: 50% !important; */
  }
  .disclaimerText2 {
    width: 90%;
  }
  .downloadButton{
    width: 90%;
    font-size: 1.2rem;
    margin: auto;
  }
  .tutorialTitle{
    width: 80%;
    font-size: 1.4rem !important;
    margin-bottom: 2rem !important;
    text-align: center;
    margin: auto;
  }
  .subPathWrapperMobile{
margin-bottom: 0rem !important;
padding-bottom: 0rem !important;
  }
.registrationNoteWrapper{
  width: 90%;
  margin-top: 1.5rem;
}
.langkahPendaftaranImg{
  width: 6rem ;;
}
.disclaimerText{
  font-size: 0.5rem;
}

}
