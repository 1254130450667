.tentangKamiImageContainer {
  width: 100%;
  height: 40rem;
  margin: auto;
  position: relative;

  z-index: 1;
}
.tentangKamiImage {
  width: 100%;
  height: 100%;
height:40rem !important;
  object-fit: cover;
  z-index: 1;
  position: relative;
  filter: url(#svgTint);
  transition: 0.5s !important;
}
.imgText{
  z-index: 2;
  height: 40rem;
  margin-top: -40rem !important;
  position: relative;
  margin-left:50%;
  text-align: right;
  margin-right:24%;
  color: white


}
.alamatCabang{
  border: 1px solid #707070;

  border-radius: 20px;
  position: relative;
}
.cabangWrapper{
  display: grid;
  grid-template-columns: 33% 33% 33%;
}
.lokasiGlobalImgContainer{
  width: 70%;
  margin: auto;
  margin-top:4rem
}
.lokasiGlobalImg{
  width: 100%;
}
.imgTextBig{
  font-family: "Montserrat", sans-serif;
  font-weight: 700; /* Black */
 padding-top: 25%;
}
.squareWhiteButton{
  padding: 5px 10px 5px 10px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  transition: 0.3s
}
.squareWhiteButton:hover {
  background-color: white;
  color:#1d5998;
  border: 2px solid white;
  /* border-color: #1d5998; */
}
.profileButtonContainer{
  text-align: center;
}
.profileButtonWrapper{


  margin: auto !important;
}
.profileButton{

  background-color: white;
  border:1px solid 
  #707070 !important; 
  width: 16rem;
  padding: 0.3rem 0px 0.3rem 0px;
  transition: 0.3s;
  margin-right:0.5rem;
  margin-left: 0.5rem;
}
.profileButton:hover{
  background-color: #0060ad;
  color: white;
  border-color: #0060ad !important;
}
.profileButtonContainer{
  margin-top: 5rem;
}
.buttonProfileActive{
  background-color: #0060ad;
  color: white !important;
  border-color: #0060ad !important;
  font-weight: 700 !important;
}
.alamatContainer{}
.alamatWrapper{
  width: 55%;
  display: grid;
  grid-template-columns: 50% 50%;
  margin: auto;
  margin-top:3rem

}
.comingAddress{
  width: 100%;
}
.lokasiTelp{
  position: absolute;
  bottom: 0;
  margin-bottom: 1.3rem;
}
.alamat{
  border: 1px solid #707070;
  height: 15rem;
border-radius: 20px;
position: relative;
}
.alamatTitle{
  /* border-bottom: 3px solid #0060ad ; */
  
}
.addressDetails{
  /* border-left: 2px solid #e2e2e2 !important; */
  white-space: pre-line;
  display: flex;

}
.alamatSpacer{
  /* border-bottom: 2px solid 
  #cecece; */
}
.locationImg{
  width: 30px;
}
.phoneImg{

  height: 23px;
}
.profileImageContainer {
  width: 75%;
  height: 25rem;
  margin: auto;
  border-radius: 4rem;
  margin-top: 2rem;
}
.profileImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.profileTitle {


  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.profileSubtitle {
  font-size: 1rem;

  

}
.pinContainer{
  width:30px;
  height:30px;;
  margin-right: 1rem;
}
.imgTextBig{
  font-size: 3.5rem
}

.w50 {
  width: 50%;
}

@media only screen and (max-width: 1400px) {
  .addressDetails{
    height: auto !important;
  }
  .profileArticleContainer{
    margin-top: 4rem;
 
  }
  .profileTextWrapper{
    width: 80%;
  }
  .alamatWrapper{
    width: 66%;
  }
}
@media only screen and (max-width: 1000px) {
  .alamatWrapper{
    display: block;
  }
  .imgText{
    margin-left: 0%;
    margin-top: -25rem !important;
    height: 25rem !important;
  }
  .tentangKamiImageContainer{
    height: 25rem !important;

  }
  .tentangKamiImage{
    height: 25rem !important;
  }


}
  @media only screen and (max-width: 768px) {
    .lokasiIndonesiaContainer{
      margin-top:7rem
    }
  .imgTextBig{
    font-size: 2rem !important;
  }
  .profileImageContainer {
    width: 90%;
    height: 10rem;
  }
  .profileImage {
    object-fit: cover;
  }

  .profileButtonContainer{
    margin-top: 2rem;
  }
  .profileButton{
    margin-top: 1rem;
  }
  .w50 {
    width: 100%;
  }
  .profileArticleContainer {
    display: block !important;
    width: 90%;
    margin: auto;
    margin-top: 3rem;
  }
  .profileArticleContainerLeft {
    width: auto;
    margin-right: 0px;
  }
  .profileArticleContainerRight {
    width: auto;
    margin-right: 0px;
  }
  .profileButtonWrapper{
    display: block;
  }
  .profileTextWrapper{
    width: 95%;
  }
}
@media only screen and (max-width: 500px) {
  .imgText{
    margin-right: 12% !important;
  }
}