.wrapper {
  height: 100%;
  position: relative;
  overflow-x: hidden;
  overflow-y: auto;
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030;
}

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1;
  background-color: #428bca !important;
  text-align: center;
}
.bg-primary {
  color: #fff;
  background-color: #428bca;
}
.text-header {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
  margin-top: 10px;
}
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 700;
}
.bg-primary {
  color: #fff;
  background-color: #337ab7;
}
.form-group {
  margin-bottom: 10px !important;
}
.wrapper:before,
.wrapper:after {
  content: " ";
  display: table;
}
.logo-long {
  width: 350px;
  height: auto;
}
.mb-10 {
  margin-bottom: 10px;
}
.content {
  min-height: 250px;
  padding: 15px;
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.box.box-primary {
  border-top-color: #3c8dbc;
}

.box {
  position: relative;
  border-radius: 3px;
  background: #ffffff;
  border-top: 3px solid #d2d6de;
  margin-bottom: 20px;
  width: 100%;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.box-header {
  color: #444;
  display: block;
  padding: 10px;
  position: relative;
}
.bg-info {
  background-color: #d9edf7 !important;
}

.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
label {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 700;
  max-width: 100%;
}
.box-body {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  padding: 10px;
}
.box-header:before,
.box-body:before,
.box-footer:before,
.box-header:after,
.box-body:after,
.box-footer:after {
  content: " ";
  display: table;
}
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
  float: left;
}

.control-label {
  font-size: 14px;
  line-height: 1.42857143;
  color: #333;
}

.box-footer {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
  border-top: 1px solid #f4f4f4;
  padding: 10px;
  background-color: #fff;
  text-align: center;
}
.signBox {
  /* width:500px; */
  height: 250px;
  border: 1px solid #dde0e6;
  margin-top: 10px;
  margin-bottom: 0px !important;
  width: 90%;
}

.signForm {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;

  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de;
}
.signBtn {
  border-radius: 3px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid transparent;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px;
}
.saveBtn {
  color: #fff;
  background-color: #367fa9 !important;
  border: 1px solid transparent;
}
.clearBtn {
  background-color: #00acd6;
  box-shadow: none;
  border: 1px solid transparent;
  color: #fff;
}
.clearBtn:hover {
  background-color: #00acd6;
  border-color: #269abc;
  color: white;
}
.col-sm-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-sm-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

@media only screen and (max-width: 768px) {
  .form-group.col-sm-12.input-sign.text-center {
    float: none !important;
  }
}
