@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
 * {
	 margin: 0;
	 padding: 0;
	 box-sizing: border-box;
}
 .App {
	 position: relative;
	 font-family: "Roboto", 'Helvetica Neue', sans-serif;
	 height: 100vh;
	 width: 100%;
}
 .App h1 {
	 position: fixed;
	 top: 3vw;
	 left: 3vw;
}
 .App .slider {
	 position: fixed;
	 top: 50%;
	 left: 6vw;
	 transform: translateY(-50%);
	 display: flex;
}
 .App .slider .slide:not(:first-child) {
	 margin-left: 6vw;
}
 .App .slider .slide .preview {
	 /* height: 350px;
	 width: 350px; */
	 width: 20rem !important;
	 height: auto;
	 background: #c4c4c4;
	 overflow: hidden;
}
 .App .slider .slide .preview img {
	 width: 100%;
	 height: 100%;
	 object-fit: cover;
	 object-position: center;
}
 .App .slider .slide .infos {
	 font-weight: light;
}
 .App .slider .slide .infos h3, .App .slider .slide .infos h2 {
	 font: inherit;
}
 .App .slider .slide .infos h3 {
	 padding-top: 15px;
	 color: #635d5d;
	 font-size: 10px;
	 text-transform: uppercase;
}
 .App .slider .slide .infos h2 {
	 font-size: 18px;
	 color: #000;
	 padding-top: 10px;
}
.slider{
	display: flex !important;
	/* width: 100% !important; */
}
.draggableSlideImg{
	width: 20rem;
}

/* #appSlide0{
	margin-left:6% !important
} */
.appSliderContainer{
	width:100%;
	margin: auto;
display: flex;
z-index: 1;
}
.appSpacerLeft{
	width: 20%;
	background-color: white;
	z-index: 99;
text-align: right;

}
.appSpacerRight{
	width: 20%;
	background-color: white;
	z-index: 99;
}
.appSliderWrapper{
	width: 60%;
	margin: auto;
	z-index: 5;
}
.appSliderInfoContainer{
	width: 90%;
	margin: auto;
	border-radius: 25px;
	border:3px solid 
	#0071bf;
	height: 8rem;
	text-align: center;
	padding: 1rem;
	margin-top: 1.5rem;
}
.stepDesc{
	font-size: 0.77rem;
}
.sliderArrow{
	height:3rem;
}
/* .appSpacerHeight{
	height: 594px;
} */
.arrowLeft{
	margin-left: auto;
	margin-right: 2rem;
	transform: rotate(-0.25turn);

}
.arrowRight{
	margin-right: auto;
	margin-left: 2rem;
	transform: rotate(90deg);

}
.appSliderMt{
	margin-top: 300px;
}

