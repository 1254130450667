

.comingSoonBigText {
    font-size: 4rem;
    color: #0061ad;
  }
  .comingSoonSmallText {
    font-size: 2rem;
  }
.notFoundContainer {
    display: grid;
    grid-template-columns: 70% 30%;
    width: 80%;
    min-height: 80vh;
    margin: auto;
  }
  
  .innerNotFoundRight {
    margin: auto;
    margin-left: 6rem;
    text-align: center;
  }
  .notFoundImageContainer {
    height: 70vh;
  }
  
  .notFoundImg {
    height: 100%;
    margin-left: -80%;
  }

  .code {
    border-right: 2px solid;
    font-size: 26px;
    padding: 0 15px 0 15px;
    text-align: center;
}
.message {
  font-size: 18px;
  text-align: center;
}
.position-ref {
  position: relative;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}
.half-height {
  height: 50vh;
}
.height-75 {
  height: 75vh;
}