.swiper-scrollbarx {
  margin-top: 2rem;
  height: 1.2rem !important;
  background: #ececec;
  border-radius: 10px;
}
.eventModalImg {
  height: 100%;
}
.eventModalImg {
  height: 35rem;
  border-radius: 20px;
}
.slider-container .swiper-wrapper .swiper-slide:first-child {
  margin-left: auto;
}

.slider-container .swiper-wrapper .swiper-slide:last-child {
  margin-right: auto;
}
.panduanWrapper {
  max-width: 66%;
}
.eventPulauImg {
  height: 55%;
  border-radius: 16px 16px 0px 0px;
}
.galleryThumbnail {
  width: 276px;
  height: 230px;
}
.slide-event-non-ed:hover {
  zoom: 10%;
}
.eventPopupDetail {
  margin: 0;
  padding: 0;
  display: flex;
  height: 35rem;
  flex-direction: column;
  justify-content: space-between;
}
.eventModal {
  align-items: stretch;
}
.event-popup > .swal2-html-container {
  margin: 0px !important;
}
.event-popup {
  padding: 0px !important;
  width: 1000px;
}
.swal2-html .swal2-modal {
  padding-bottom: 0px !important;
}
.loadin .eventModal {
  border-radius: 20px;
}
.eventCabang {
  overflow: auto;
  flex-wrap: nowrap;
  overflow-y: auto;
  overflow-x: hidden;
  width: 10000px;
}

.eventCard {
  width: 330px;
  height: 470px;
  border-radius: 16px;
  background-color: #f3f6f8;
}
.swiperNgobrol > .swiper-wrapper {
  transition-timing-function: linear !important;
}
.cabangTabButton {
  width: auto;
  border: 1px solid #0071bf;
  border-radius: 13px;
  background-color: #dfebf2;
  transition: 0.3s;
}
.cabangTabButton:hover {
  background-color: #0071bf;
  color: white;
}
.cabangTabButtonActive {
  background-color: #0071bf;
  color: white !important;
}
.panduanDetailContainer {
  border-radius: 16px;
  width: 31%;
  background-color: #6c99d7;
}

.py18r.table-center.h100 {
  height: 7.99rem;
}
.panduanImg {
  margin-top: -4rem;
  position: relative;
  z-index: 2;
}
.alamatCabang {
  border: 1px solid #707070;
  display: flex;

  flex-direction: column;
  justify-content: space-between;
  border-radius: 20px;
  position: relative;
}
.manualButton {
  background-color: #dfebf2;
  transition: 0.15s;
  border: 2px solid #0071bf;
}
.manualButton:hover {
  background-color: #6c99d7;
  color: white;
  border-color: white;
}
.cabangDetail {
  min-height: 120px;
}
.panduanButton {
  background-color: transparent !important;
  padding: 0rem 1rem 0rem 1rem !important;
  border: 1px solid #707070;
  border-radius: 12px;
}
.panduanButton:hover {
  background-color: #0071bf !important ;
  border-color: #0071bf !important;
  color: white;
  background-color: #0071bf;
  color: white !important;
  text-shadow: none !important;
  box-shadow: 0 1px 10px rgb(0 113 191);
}
@media only screen and (max-width: 1000px) {
  .cabangWrapper {
    display: block;
  }
  .cabangMain {
    margin-top: 6rem !important;
  }
  .eventModal {
    display: block !important;
  }
  .eventModalImg {
    height: 25rem;
  }
  .eventPopupDetail {
    height: auto;
  }
  .backCabang {
    margin-top: 5rem !important;
  }
  .swalEvent {
    max-width: 90%;
  }
}

@media only screen and (max-width: 768px) {
  .eventModalImg {
    height: 20rem;
  }
  .arrowCabangL {
    margin-left: 1rem !important;
    height: 1.8rem !important;
  }
  .arrowCabangR {
    margin-right: 1rem !important;
    height: 1.8rem !important;
  }
}
